angular.module('deitz')
    .controller('addEmailTemplateController',
            ['$scope',
            '$rootScope',           
            'codeFactory',
            '$http',
            '$state',
            'Notification',
            'apiUrl',
            '$uibModal',
            function ($scope,
            $rootScope,              
            codeFactory, 
            $http,
            $state,
            Notification,
            apiUrl,
            $uibModal) {
        $scope.page_title = $state.current.data.pageTitle;
        
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.nameChangedError = false;  		
		$scope.formData = {};
        $scope.variables = [
            "{{businessunit_name}}","{{businessunit_address_1}}","{{businessunit_address_2}}","{{businessunit_address_3}}","{{businessunit_phone}}","{{job_no}}","{{job_status}}","{{job_date}}","{{job_time}}","{{case_caption}}","{{witness_list}}","{{turnaround}}","{{connection_method}}","{{job_type}}","{{location}}","{{services}}","{{pre_exhibits}}","{{upload_exhibit_link}}","{{job_note}}","{{scheduling_party_firm}}","{{attorney_row}}","{{scheduling_party_scheduled_by}}","{{bill_to}}","{{client_file_number}}","{{claim_number}}","{{claim_adjuster}}","{{scheduling_email_footer}}","{{vc_link}}","{{webex_meeting_number}}","{{webex_url}}","{{webex_password}}","{{webex_phones}}","{{zoom_join_url}}","{{zoom_meeting_id}}","{{zoom_meeting_password}}","{{schedule_type}}","{{logo_header}}"
          ]



        /*ckeditor start */

    const {
        ClassicEditor,
        SourceEditing,
        Essentials,
        Bold,
        Italic,
        Font,
        Paragraph,
        Table,
        Image,
        ImageCaption,
        ImageStyle,
        GeneralHtmlSupport,
        Heading,
        FullPage,
        ButtonView,
        SpecialCharacters,
        SpecialCharactersEssentials      
    } = CKEDITOR;  
       
        $scope.editor = '';
        $scope.formData = {
            email_content: ''
        };
        $scope.status = ['Active', 'Inactive'];           
        $scope.toolbarItems = [
            'undo', 'redo', '|', 'bold', 'italic', '|',
                'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', 'SourceEditing','insertTable','insertImage','heading', 'preview', 'specialCharacters'
        ];

        $scope.initEditor = function(){          

            ClassicEditor
            .create( document.querySelector( '#htmleditor' ), {                            
                toolbar: {
                    items: $scope.toolbarItems
                },  
                plugins: [ Essentials, Bold, Italic, Font, Paragraph, SourceEditing,Table,Image, ImageCaption, ImageStyle, GeneralHtmlSupport,Heading, FullPage, SpecialCharacters, SpecialCharactersEssentials],
                htmlSupport: {
                    allow: [
                        {
                            name: /.*/,
                            attributes: true,
                            classes: true,
                            styles: true
                        }
                    ]
                },                                     
                autoParagraph: false,
                allowContentInside : false,
                table: {
                    contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ],
                    // Prevent wrapping tables in <figure class="table">
                    tableProperties: {
                        class: '' // Add an empty string to prevent the default class
                    }
                },
                image: {
                    toolbar: ['imageTextAlternative', 'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight']
                }, 
                extraPlugins: [
                    function (editor) {
                        editor.ui.componentFactory.add('preview', locale => {
                            const button = new ButtonView(locale);
                            button.set({
                                label: 'Preview',
                                icon: '<svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" width="100" height="100"><path d="M1 8c3.25 0 5 3 7 4s3.75 2 7 0c-2.25-1.25-4.5-2.25-7-2s-5 3-7 4z"></path></svg>',
                                tooltip: true
                            });
                            button.on('execute', () => {
                                // Get the editor data and show it in a preview section or modal
                                const previewContent = $scope.editor.getData();                                
                                $scope.showPreview(previewContent);
                            });
                            return button;                           
                        });
                    }
                ]                            
            })
            .then(newEditor => {
                $scope.editor = newEditor;
                $scope.editor.setData($scope.formData.email_content);             
            })
            .catch(error => {
                console.error('There was a problem initializing the editor:', error);
            });
        }
       
        $scope.showPreview = function (content) {       
            $rootScope.showLoader(true);
            var PreviewModal = $uibModal.open({
                templateUrl: "modules/email_template/preview.html",
                controller: 'PreviewEmailTemplateController',   
                resolve: {                   
                    previewContent: function () {
                        return content; // Pass the content to the modal
                    }
                },           
                keyboard: false,
                backdrop: false,
                close: function () {
                }
            });

            PreviewModal.opened.then(function(){
                $rootScope.showLoader(false);
            });
        };

        $scope.isNameUnique = true;

        // Function to check if the name is unique
        $scope.checkUniqueName = function(name) {
            // Check if we're in edit mode (i.e., if there's an ID in the state params)
            if ($state.params.id) {
                // In edit mode, compare the new name to other records but exclude the current record's name
                if (name !== $scope.formData.originalName) {  // Compare with the original name
                    $http.post(apiUrl + '/api/check_email_templte_name_unique', { name: name })
                        .then(function(response) {
                            $scope.isNameUnique = response.data.isUnique;
                            $scope.email_temp_form.name.$setValidity("unique", $scope.isNameUnique);
                        })
                        .catch(function(error) {
                            console.error('Error checking name uniqueness:', error);
                        });
                } else {
                    // If the name is not changed, set it as valid
                    $scope.email_temp_form.name.$setValidity("unique", true);
                }
            } else {
                // If it's a new record, proceed with the usual uniqueness check
                if (name) {
                    $http.post(apiUrl + '/api/check_email_templte_name_unique', { name: name })
                        .then(function(response) {
                            $scope.isNameUnique = response.data.isUnique;
                            $scope.email_temp_form.name.$setValidity("unique", $scope.isNameUnique);
                        })
                        .catch(function(error) {
                            console.error('Error checking name uniqueness:', error);
                        });
                }
            }
        };
        
        


        /*editor*/
        $scope.cleanUpContent = function(content) {
            // Remove empty <p> tags and their content           
            return content.replace(/<figure[^>]*>|<\/figure>/g, '');
        } 
        $scope.submitEmailTemplate = function (isValid) {  
                 
            $scope.formSubmit = true; 
            $scope.formData.status = $scope.formData.status.toLowerCase();  
            $scope.formData.email_content = $scope.editor.getData();

            var submit_data = $scope.formData;             
            submit_data.email_content = $scope.editor.getData();             
            submit_data.email_content = $scope.cleanUpContent(submit_data.email_content);    
            
            if ($scope.nameChangedError) {
                Notification.error('Form submission prevented due to name change error');
                return;                
              }            
            if (!submit_data.email_content || submit_data.email_content.replace(/<[^>]*>/g, '').replace(/\s*&nbsp;\s*/g, '').trim() === "") {
                // If empty, the form is invalid
                Notification.error('Please Add Description');
                return;   
            }            
            if(isValid){   
                $rootScope.showLoader(true); 
                if($state.params.id){                    
                    $http.put(apiUrl+'/api/email-template/'+$state.params.id, submit_data).success(function(response){						
						$rootScope.showLoader(false);
						if(response.error){
							Notification.error(response.result.message);
						}else{
							Notification.success(response.result.message);
                            $state.go('index.email_template_browse');						
						}
					});
                }else{
                    $http.post(apiUrl+'/api/email-template',submit_data).success(function(response){
                        $rootScope.showLoader(false);
                        if(response.error){
                            Notification.error(response.result.message);                
                        }else{                
                            Notification.success(response.result.message);
                            $state.go('index.email_template_browse');                
                        }            
                    });
                }
            }
        };

        //get data for edit screen
		if($state.params.id){
			$http.get(apiUrl+'/api/email-template/'+$state.params.id+'/edit').success(function(response){
				if(response.error){					
					Notification.error(response.result.message);
				}else{
					var resValue = response.result.data;
					$scope.formData.name = resValue.name;				
                    $scope.formData.dept_id = resValue.dept_id;					
                    $scope.formData.receipts = resValue.receipts;					
                    $scope.formData.temp_category_id = resValue.temp_category_id;					
                    $scope.formData.temp_style = resValue.temp_style;					
                    $scope.formData.trigger = resValue.trigger;					                    
                    $scope.formData.email_content = resValue.email_content;  
                    if(resValue.variables){
                    $scope.variables = resValue.variables.split(',').map(item => item.trim());;   
                    }
                                
                   
                    if (resValue.status === 'active') {
                        $scope.formData.status = 'Active';
                    } else if (resValue.status === 'inactive') {
                        $scope.formData.status = 'Inactive';
                    }
                 
                    setTimeout(function(){ $scope.initEditor();}, 500);	                    	
				}
			});


            $scope.setOriginalName = function() {
                if (!$scope.formData.originalName) {  // Only set it once
                  $scope.formData.originalName = $scope.formData.name;
                }
              };
              
              // Function to check if the user is trying to change the name after it has been set
              $scope.checkIfChanged = function(newName) {
                console.log('Current name: ', newName);
                console.log('Original name: ', $scope.formData.originalName);
              
                // Check if the name was set and if it's different from the original name
                if ($scope.formData.originalName && newName !== $scope.formData.originalName) {
                  $scope.nameChangedError = true; 
                   // Set error if the name is being changed after submission
                } else {
                  $scope.nameChangedError = false;  // Reset error if it's a valid change
                }
                console.log($scope.nameChangedError);
              };
    
            $scope.$watch('formData.name', function(newVal, oldVal) {
                if (newVal && !oldVal) {
                  $scope.setOriginalName();  // Set original name when it's first entered
                }
              });

		}else{
            $scope.formData.status = 'Active';
            $scope.initEditor();
        } 
         //get all department
         $http.get(apiUrl + '/api/email-template-department').success(function(response) {            
            $scope.DeptList=response.result
        });
        //get all department
        $http.get(apiUrl + '/api/email-template-category').success(function(response) {            
            $scope.Temp_Cat_List=response.result
        });      
    }]);