angular.module('deitz')
            .controller('caselistController', [
                    '$scope',
                    '$rootScope',
                    'commonFactory',
                    '$http',
                    'apiUrl',
                    'SweetAlert',
                    '$state',
                    '$window',
                    'Notification',
                    'DTOptionsBuilder',
                    'DTColumnBuilder',
                    '$compile',
                    '$uibModal',
                    '$filter',
                    'invoiceFactory',
                    '$timeout',
                    '$q',
                    function(
                        $scope,
                        $rootScope,
                        commonFactory,
                        $http,
                        apiUrl,
                        SweetAlert,
                        $state,
                        $window,
                        Notification,
                        DTOptionsBuilder,
                        DTColumnBuilder,
                        $compile,
                        $uibModal,
                        $filter,
                        invoiceFactory,
                        $timeout,
                        $q
                    ){

        $scope.title = 'Job Resource listing';
        $scope.searchString = JSON.parse(localStorage.getItem('searchString'));
        $scope.excludeBilled = false;
        $scope.downloadExcel = false;
        $scope.lookingFor = localStorage.getItem('lookingFor');
        $scope.assignmentTypes = [
            {'type':'','name':'-- ALL JOBS --'},
            {'type':'audio','name':'AUDIO JOBS'},
            {'type':'geico','name':'GEICO JOBS'},
            {'type':'reporter','name':'RESOURCE JOBS'} 
        ];
        $scope.isFilterApplied = false;

        $scope.sort_order = 'desc';
        $scope.sort_by = 0;

        var addTomorrowDay = 1;

        if(moment().format('dddd') === 'Friday')
            addTomorrowDay = 3;

        if(moment().format('dddd') === 'Saturday')
            addTomorrowDay = 2;

        $scope.formData = {
            todaysRecord: false,
            tomorrowsRecord: false,
            today: moment().format('MM/DD/YYYY'),
            tomorrow: moment().add(addTomorrowDay, 'days').format('MM/DD/YYYY'),
            todayText : moment().format('dddd'),
            tomorrowText : moment().add(addTomorrowDay, 'days').format('dddd')
        };

        if(!$scope.searchString || !$scope.searchString.selectedValue){
            $state.go('diary.findcase');
        }
        
        if($scope.searchString && $scope.searchString.selectedValue == 'CAPTION'){
            $scope.selectedCaseName = $scope.searchString.searchValue;
        }

        if($scope.searchString && $scope.searchString.selectedValue == 'witness_name'){
            $scope.selectedWitnessName = $scope.searchString.searchValue.witness_name;
        }

        if($scope.searchString && $scope.searchString.selectedValue == 'IndexNumber'){
            $scope.selectedCourtIndexNumber = $scope.searchString.searchValue;
        }
        if($scope.searchString && $scope.searchString.selectedValue == 'all_open_jobs'){
            $scope.title = 'All Open Jobs Listing';
        }
        
        if($scope.searchString && $scope.searchString.selectedValue == 'pending_jobs'){
            $scope.title = 'Pending Jobs Listing';

            if($scope.searchString.searchValue == 'todays_pending_jobs'){
                $scope.formData.todaysRecord = true;
                $scope.exclude_cancelled_jobs = true;
            }
            if($scope.searchString.searchValue == 'tomorrows_pending_jobs'){
                $scope.formData.tomorrowsRecord = true;
                $scope.exclude_cancelled_jobs = true;
            }
        }
        $scope.isDateScheduled = false;
        
        if($scope.searchString && $scope.searchString.selectedValue === 'AssignmentCreatedDate'){
            $scope.selectedAssignmentCreatedDate = {};
            $scope.selectedAssignmentCreatedDate.startDate = $scope.searchString.searchValue.startDate;
            $scope.selectedAssignmentCreatedDate.endDate = $scope.searchString.searchValue.endDate;
        }else{
            $scope.selectedAssignmentCreatedDate = null;
        }

        if($scope.searchString && $scope.searchString.selectedValue === 'CreatedBy'){
            $scope.selectedAssignmentCreatedBy = $scope.searchString.searchValue ;
        }

        if($scope.searchString && $scope.searchString.selectedValue === 'created_at'){
            $scope.isDateScheduled = true;
            $scope.dateScheduled = {};
            $scope.dateScheduled.startDate = $scope.searchString.searchValue.startDate;
            $scope.dateScheduled.endDate = $scope.searchString.searchValue.endDate;

            $scope.sort_order = 'asc';
            $scope.sort_by = 4;
        }

        $scope.reporterType = {'R':'Reporter', 'T':'Typist','SC':'Scopist','S':'Account Executive','V':'Videographer'};

        $scope.dtInstance = {};
 
        $scope.reloadData = function () {
            // $scope.dtInstance.rerender();
            $scope.dtInstance.DataTable.ajax.reload();
        };
        $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
            $scope.BusinessUnitsList.unshift({name:'All'});
        });       

        $http.get(apiUrl + '/api/getJobStatus').then(function(response) { 
            $scope.JobStatusList = response.data.result.data;  
            $scope.JobStatusList.unshift({name:'All'});
        });

        $scope.selectedJobStatusIDs = [];

        $scope.selectedServiceStatusIDs = [];
        $http.get(apiUrl + '/api/getServiceStatus').then(function(response) { 
            $scope.ServiceStatusList = response.data.result.service_status;  
            $scope.ServiceStatusList.unshift({name:'All'});
        });

        $http.get(apiUrl + '/api/casetypes').then(function(response) { 
            $scope.CaseTypeList = response.data.result.casetype;  
            $scope.CaseTypeList.unshift({name:'All'});
        });

        $scope.selectedCaseTypeIDs = [];        

        $http.get(apiUrl + '/api/getJobCategory').then(function(response) { 
            $scope.JobCategoryList = response.data.result.jobcategory;  
            $scope.JobCategoryList.unshift({name:'All'});
        });

        $scope.selectedJobCategoryIDs = [];

        $scope.selectedJobTypeIDs = [];        
        $http.get(apiUrl + '/api/jobtypes').then(function(response) { 
            $scope.JobTypeList = response.data.result.jobtype;  
            $scope.JobTypeList.unshift({name:'All'});
        });
        $scope.selectedResourceTypeIDs =[];
        $http.get(apiUrl + '/api/additionalinfo_list').then(function(response) { 
            $scope.ResourceTypeList = response.data.result.data;  
            $scope.ResourceTypeList.unshift({name:'All'});
        });

        $scope.selectedRateTableIDs = [];
        commonFactory.get('/api/rates')
           .success(function(response) { 
               if (response.result && response.result.rates) {
                   $scope.insuranceRates = response.result.rates;
               }
           })
           .error(function(err) {});

        $scope.selectedRateGroupIDs = [];
        commonFactory.get('/api/rateCardGroups')
            .success(function (response) {
                if (response.result) {
                    $scope.rateCardGroups = response.result.rateCardGroup;
                }
            }).error(function (err) {}); 

        $scope.onInputChange = function(selectedAssignmentCreatedBy) {
            if (!selectedAssignmentCreatedBy) {
                // If input box is cleared (empty string), reload the data
                $scope.reloadData();
            }
        };
        //Getting assignment created byn ame.
        $scope.getcasebyassignmentcreatedbyname = function(val) { 
            return $http.get(apiUrl + '/api/getcasebyassignmentcreatedbyname', {
                params: {
                    name: val
                }
            }).then(function(response) { 
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        $scope.selectedJobRequestSources = [];
        commonFactory.get('/api/job-request-sources')
            .success(function(response) { 
                if (response.result && response.result.job_request_sources) {
                    $scope.jobRequestSources = response.result.job_request_sources;
                    $scope.jobRequestSources.unshift({name:'All'});
                }
            })
            .error(function(err) {});


        $scope.selectedBusinessUnitIDs = [];
        var selectedBusinessUnitIDs1 = [];
        var selectedBusinessUnitIDs2 = JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
        $http.get(apiUrl + '/api/getUserBusinessUnit', {
            params: {
                user_id: $rootScope.auth_user.id
            }
        }).then(function (response) {
            if (response.data.status == 200) {
                response.data.result.data.forEach(function (e) {
                    selectedBusinessUnitIDs1.push(e.business_unit_id)
                });
            }
        });
        if (selectedBusinessUnitIDs1 && selectedBusinessUnitIDs1.length) {
            $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs1;

        } else if (selectedBusinessUnitIDs2 && selectedBusinessUnitIDs2.length) {
            $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs2;
        } else {
            $rootScope.auth_user.roles_businessunits.forEach(function (e) {
                $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
            });
        }                            
         
        $scope.createdRow = function (row, data, dataIndex) {
            // Recompiling so we can bind Angular directive to the DT
            $compile(angular.element(row).contents())($scope);
        };

        $scope.onToggle = function(type){
            if($scope.searchString && $scope.searchString.selectedValue == 'pending_jobs'){
                if(type == 'tomorrow'){
                    $scope.formData.todaysRecord = !$scope.formData.tomorrowsRecord;                    
                }
                if(type == 'today'){
                    $scope.formData.tomorrowsRecord = !$scope.formData.todaysRecord;                    
                }
            }
            $scope.reloadData();
        }

        $scope.timeoffConflicts = function(repoters){
            swal('Time Off Conflicted Job / Not Working Day', repoters+' -- They’ve applied for time off request or not worked on that day. Thank you.');
        }

        $scope.downloadExcelFile = function () {
            $scope.downloadExcel = true;
            $scope.reloadData();
        }

        $scope.savepreferedview = function (state_Data) {          
        $scope.state_Data = state_Data;
            commonFactory.post('/api/statesave/'+$rootScope.auth_user.id , $scope.state_Data)
            .success(function (response) {
                if (response) {                             
                $scope.statedata1 = response;
                if(response.result.message == 'create'){
                    Notification.success("Prefered View Created Succesfully.");                                  
                }
                if(response.result.message == 'update'){
                    Notification.success("Prefered View Updated Succesfully.");                                  
                } 
            }
            });
        } 
        $scope.searchWitness = function () {
            if ($scope.selectedWitnessName.length > 2) {
                $scope.reloadData()
            }
        }
        // set current state view of loged in user
        $scope.updateCurrentStateView = function (key) {
            commonFactory.post('/api/updatecurrentstateview/'+$rootScope.auth_user.id , { key })
            .success(function (response) {
                if (response) {                             
                    $scope.statedata1 = response;
                    Notification.success("Prefered View Change Succesfully.");                                  
                }
            });
        }   
       $scope.removepreferedview = function (key) {          
            commonFactory.post('/api/removestatesave/'+$rootScope.auth_user.id+'/'+key)
            .success(function (response) { 
                if(response.result.message == 'remove'){
                    Notification.success("Prefered View Removed Succesfully.");                                  
                }                              
            });
        }       
        $scope.statedata = '';
        $scope.getpreferedview = function () {  
            commonFactory.post('/api/getstatesave/'+$rootScope.auth_user.id)
                .success(function (response) {
                    if (response) { 
                        $scope.statedata = response;                                  
                    } 
                });

        }
        $scope.selectedState = null;
        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data')
            .withOption('ajax', function (data, callback, settings) {
                if ($scope.downloadExcel) {
                    $rootScope.showLoader(true);
                }
                $scope.inProcess = true;
                angular.extend(data, $scope.searchString);
                data.countOnly = false;
                data.exclude_billed_jobs = $scope.exclude_billed_jobs;
                data.exclude_cancelled_jobs = $scope.exclude_cancelled_jobs;
                data.assignmentType = $scope.lookingFor;
                data.downloadExcel = $scope.downloadExcel;
                data.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);
                data.selectedJobStatusIDs = $scope.selectedJobStatusIDs.filter(Boolean);
                data.selectedCaseName = $scope.selectedCaseName;
                data.selectedWitnessName = $scope.selectedWitnessName;
                console.log('data.selectedWitnessName: ', data.selectedWitnessName);    
                data.selectedCourtIndexNumber = $scope.selectedCourtIndexNumber;
                data.selectedGroupName = $scope.selectedGroupName;
                data.selectedFirmDesignation = $scope.selectedFirmDesignation;
 
                data.selectedServiceStatusIDs = $scope.selectedServiceStatusIDs.filter(Boolean);
                data.selectedCaseTypeIDs = $scope.selectedCaseTypeIDs.filter(Boolean);
                data.selectedJobCategoryIDs = $scope.selectedJobCategoryIDs.filter(Boolean);
                data.selectedJobTypeIDs = $scope.selectedJobTypeIDs.filter(Boolean);
                data.selectedResourceTypeIDs = $scope.selectedResourceTypeIDs.filter(Boolean);
                data.selectedRateTableIDs = $scope.selectedRateTableIDs.filter(Boolean);
                data.selectedRateGroupIDs = $scope.selectedRateGroupIDs.filter(Boolean);
                data.selectedJobRequestSources = $scope.selectedJobRequestSources.filter(Boolean);
                data.filterParams = $scope.formData;
                data.user_id = $rootScope.auth_user.id;
                if(data.assignmentType || data.selectedCaseName || data.selectedWitnessName || data.selectedCourtIndexNumber || $scope.selectedGroupName || $scope.selectedFirmDesignation || $scope.selectedAssignmentCreatedBy || $scope.selectedAssignmentCreatedDate || $scope.formData.tomorrowsRecord || $scope.formData.todaysRecord || (data.selectedRateTableIDs && data.selectedRateTableIDs.length > 0) || (data.selectedRateGroupIDs && data.selectedRateGroupIDs.length > 0) || (data.selectedJobStatusIDs && data.selectedJobStatusIDs.length > 0) || (data.selectedServiceStatusIDs && data.selectedServiceStatusIDs.length > 0) || (data.selectedJobRequestSources && data.selectedJobRequestSources.length > 0) || (data.selectedCaseTypeIDs && data.selectedCaseTypeIDs.length > 0) || (data.selectedJobTypeIDs && data.selectedJobTypeIDs.length > 0) || (data.selectedJobCategoryIDs && data.selectedJobCategoryIDs.length > 0) || (data.selectedResourceTypeIDs && data.selectedResourceTypeIDs.length > 0)){
                    $scope.isFilterApplied = true;
                }

                if($scope.searchString && $scope.searchString.selectedValue == 'created_at'){
                    data.searchValue.startDate =  moment($scope.dateScheduled.startDate).format('YYYY-MM-DD');
                    data.searchValue.endDate =  moment($scope.dateScheduled.endDate).format('YYYY-MM-DD');

                    $scope.title = 'Job Resource listing - ['+ moment($scope.dateScheduled.startDate).format('MM/DD/YYYY') +' TO '+ moment($scope.dateScheduled.endDate).format('MM/DD/YYYY')+']';
                }              
                if($scope.selectedAssignmentCreatedDate && $scope.selectedAssignmentCreatedDate.startDate && $scope.selectedAssignmentCreatedDate.startDate){
                    data.selectedAssignmentCreatedDate = {};
                    data.selectedAssignmentCreatedDate.startDate =  moment($scope.selectedAssignmentCreatedDate.startDate).format('YYYY-MM-DD');
                    data.selectedAssignmentCreatedDate.endDate =  moment($scope.selectedAssignmentCreatedDate.endDate).format('YYYY-MM-DD');

                    $scope.title = 'Job Resource listing - ['+ moment($scope.selectedAssignmentCreatedDate.startDate).format('MM/DD/YYYY') +' TO '+ moment($scope.selectedAssignmentCreatedDate.endDate).format('MM/DD/YYYY')+']';
                }              

                if ($scope.selectedAssignmentCreatedBy) {
                    data.selectedAssignmentCreatedBy = $scope.selectedAssignmentCreatedBy.id ? $scope.selectedAssignmentCreatedBy : '';
                }
                localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean)));
                commonFactory.showDataTable('/api/casexists', data)
                .success(function(res) {
                    if (res.error) {
                        $scope.recordsTotal = 0;
                        $state.go('diary.findcase');
                    } else{
                        $rootScope.showLoader(false);
                        $scope.recordsTotal = res.recordsTotal;
                        $scope.caseData = res.data;
                        $scope.inProcess = false;
                        callback(res);                        
                        if ($scope.downloadExcel) {
                            // $window.location.href = apiUrl + '/api/download-job-listing-report';
                            $window.open(apiUrl + '/api/download-jobs-listing-report', '_blank');
                        }
                        $scope.downloadExcel = false;
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                    $state.go('diary.findcase');

                });
            })
            .withOption('processing', true)
           .withLanguage({
                "sEmptyTable": "NO RECORDS AVAILABLE IN TABLE",
                "sInfo": "SHOWING _START_ TO _END_ OF _TOTAL_ RECORDS",
                "sInfoEmpty": "SHOWING 0 TO 0 OF 0 INVOICES",
                "sInfoFiltered": "(FILTERED FROM _MAX_ TOTAL RECORDS)",
                "sInfoPostFix": "",
                "sInfoThousands": ",",
                "sLengthMenu": "SHOW _MENU_ RECORDS",                
                "sProcessing": "<img src='img/loading_bar.gif'/>",
                "sSearch": "Search :",
                "sZeroRecords": "NO MATCHING RECORDS FOUND",
                "oPaginate": {
                    "sFirst": "FIRST",
                    "sLast": "LAST",
                    "sNext": "NEXT",
                    "sPrevious": "PREVIOUS"
                },
                "oAria": {
                    "sSortAscending": ": ACTIVATE TO SORT COLUMN ASCENDING",
                    "sSortDescending": ":   ACTIVATE TO SORT COLUMN DESCENDING"
                }
            })
            .withOption('serverSide', true)
            .withOption('stateSave', false)                                   
            .withDisplayLength(100)
            .withPaginationType('simple_numbers')
            .withOption('searchDelay', 500)
            .withOption('order', [$scope.sort_by, $scope.sort_order])
            .withOption('lengthMenu', [
                [100, 200, 300, 400, 500],
                [100, 200, 300, 400, 500]
            ])          
            .withOption('createdRow', $scope.createdRow)
            .withOption('colReorder',true)           
            .withOption('scrollX', true)   
            .withOption('scrollY', '100vh') 
            .withOption('scrollCollapse',true)     
            .withOption('fnInitComplete', function(){                
                // Disable TBODY scoll bars
                $('.dt-scroll-body').css({
                    'overflow': 'inline',
                    'border': '0'
                });                
                // Enable TFOOT scoll bars
                $('.dt-scroll-foot').css('overflow', 'auto');                
                $('.dt-scroll-head').css('overflow', 'auto');
                                
                // Sync TFOOT scrolling with TBODY
                $('.dt-scroll-foot').on('scroll', function () {
                    $('.dt-scroll-body').scrollLeft($(this).scrollLeft());
                });
                $('.dt-scroll-head').on('scroll', function () {
                    $('.dt-scroll-body').scrollLeft($(this).scrollLeft());
                });   
                //console.log( $scope.dtInstance.DataTable);
                // commonFactory.post('/api/getuserstatesave/'+$rootScope.auth_user.id)
                // .success(function (response) {
                //        // Get the loaded state   
                //        if(response != 0){                                   
                //         $scope.dtInstance.DataTable.stateRestore.state(response).load();                        
                //        }
                // }); 
                if($scope.selectedState){
                    $scope.dtInstance.DataTable.stateRestore.state($scope.selectedState).load(); 
                }
            })
              
            .withOption('headerCallback', function (header) {
                console.log('1');
                // Use this headerCompiled field to only compile header once
                if (!$scope.headerCompiled) {
                    $compile(angular.element(header).contents())($scope);
                }
                $timeout(function () {                 
                    $scope.dtInstance.DataTable.columns.adjust();
                    $scope.dtInstance.DataTable.responsive.recalc();                              
                }, 100);
                
            })
            .withOption('fnRowCallback', function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {  
                console.log('2');                                
                if(aData.schedule_type == 'client_form' || aData.schedule_type == 'progressive_form'){
                    $('td.job_legacy', nRow).css('background-color', 'yellow');                      
                }
                if(aData.schedule_type == 'litify' || aData.schedule_type=='shulman-hill-litify'){
                    $('td.job_legacy', nRow).css({'background-color':'#8000FF','color':'#fff'});                                    
                }
                if(aData.diary_rec.location_id=='10'){
                    $('td.job_legacy', nRow).css({'background-color':'#8000FF','color':'#fff'});                   
                }
                if(aData.case_type_id=='1'){
                    $('td.job_legacy', nRow).css('background-color', 'pink');                    
                }
                /*if(aData.diary.business_unit_id != aData.diary.covering_business_unit_id){
                    $('td', nRow).css({'background-color':'#8000FF','color':'#fff'});                                    
                }*/              
            })
            .withOption('drawCallback', function(settings) {                
                angular.element(document).find('.cbu_job').parent().addClass('bg-cbu'); 
                if($scope.recordsTotal == 1){
                    $('.dt-scroll-body').css('min-height', '210px');   
                }else{
                    $('#diarylist tbody tr:last-child td:last-child div').addClass('dropup');      
                }                        
              })
            .withDOM('<"html5buttons"B>lTfgtip')
            .withButtons([                
                { extend: 'colvis',
                  text:'Show/Hide Columns',                  
                  init: function (dt, button, conf) {
					dt.on('column-visibility.dt' + conf.namespace, function (e, settings) {   
						$scope.dtInstance.DataTable.columns.adjust();
                        $scope.dtInstance.DataTable.responsive.recalc();   						
					});
				    }                    
                },
                {   extend :'createState',
                    text : 'Create/Update Prefered view',
                    className : 'createuserstate',
                    config: {creationModal: true} 
                },
                {
                    extend: 'savedStates',
                    text: 'Prefered view', 
                    config: {                                                
                        rename: false,                                                 
                        ajax: function (data, callback) {
                            if (data.action === 'load') {                    
                                commonFactory.post('/api/getstatesave/'+$rootScope.auth_user.id)
                                .success(function (response) {
                                    if (response) { 
                                        var loadData =  response.result.data;  
                                        var reloadStates = (loadData) => {                                                   
                                            var cbData = {};
										    for (var i = 0; i < loadData.length; i++) {
                                                // Convert back from the string created when saving
                                                if (loadData[i].id === response.result.dairy_list_current_view) {
                                                    $scope.selectedState = loadData[i].key;
                                                }
                                                var data1 = JSON.parse(loadData[i].statedata);
                                                cbData[data1.identifier] = JSON.parse(data1.state);
										    }
                                            callback(cbData);
                                        }; 
                                        reloadStates(loadData);
                                    } 
                                });                                
                            } else if (data.action === 'save') { 
                                    var ids = Object.keys(data.stateRestore);                                    
                                    var states = [];
                                    for (var i = 0; i < ids.length; i++) {                               
                                        let temp = {};
                                        temp.identifier = ids[i];
                                        temp.state = JSON.stringify(data.stateRestore[ids[i]]);
                                        states.push(temp);                               
                                    }    
                                    $scope.savepreferedview(JSON.stringify(states[0]));
                                                                    
                                callback();                                                                       
                            } else if (data.action === 'remove') {                                  
                                var ids = Object.keys(data.stateRestore);                              
                                $scope.removepreferedview(ids[0]);                                 
                                callback();                                                                                             
                            }
                        }          	
                    },                   
                }                                                            
            ]);

            

            // Add this event handler for dynamically generated elements
            $(document).on('click', '.dtsr-state', function() {
                var selectedState = $(this).text();
                $scope.updateCurrentStateView(selectedState)
                $scope.$apply(); // Ensure the $scope is updated
            });

            $scope.dtColumns = [];

            $scope.dtColumns.push(

                DTColumnBuilder.newColumn('LAST_JOB').withTitle('JobNumber').withOption('searchable', true).withClass('job_legacy').renderWith(function(data,type,full,meta){                  
                    legacy_job_no = '';
                    if(full.diary_rec.legacy_job_no){
                        legacy_job_no = '<br><hr><span style="color:grey">'+full.diary_rec.legacy_job_no+'</span>';
                    }
                    link_status = '';
                    if(full.is_zoom_link_send == 1 && full.meeting_host_type == 'Z'){
                        link_status = '<br><hr><a target="_blank" href="'+full.vc_link+'" class="label label-success">Link Sent</a>'
                    }
                    if(full.is_zoom_link_send == 0 && full.meeting_host_type == 'Z'){
                        link_status = '<br><hr><a target="_blank" href="'+full.vc_link+'" class="label label-danger">Link Not Sent<a>'
                    }
                    if(full.is_zoom_link_send == null || full.meeting_host_type != 'Z'){
                        link_status =  ''
                    }

                    var litify_style='';
                    var label='';
                    var purplea = '';
                    
                        if(full.schedule_type == 'client_form' || full.schedule_type == 'progressive_form'){
                            label="label label-warning";
                            litify_style="background-color:yellow;border-radius: .25em;padding:3px";
                        }
                        if(full.schedule_type == 'litify' || full.schedule_type=='shulman-hill-litify'){
                            label="label";
                            litify_style="background-color:#8000FF;color:#fff;border-radius: .25em;padding:3px";
                            purplea = 'color:#fff';
                        }
                        if(full.diary_rec.location_id=='10'){
                            label="label";
                            litify_style="background-color:#8000FF;color:#fff;border-radius: .25em;padding:3px";
                            purplea = 'color:#fff';

                        }
                        if(full.case_type_id=='1'){
                            label="label";
                            litify_style="background-color:pink;color:#000;border-radius: .25em;padding:3px";
                        }
                      label='';
                      var litify_style='';
                      
                    if(full.diary_rec && full.diary_rec.exhibit_uploaded_by_client.length > 0){
                        return '<span class="'+label+'" style="'+litify_style+'"><a target="_blank" style="'+purplea+'" ui-sref="diary.editcase.step_one({id: '+ data +'})" href="#/diary/step_one/'+data+'">'+data+'</a><a href="'+$rootScope.depoHost+'/upload_exhibit_by_client.php?JOB_NO='+data+'" target="_blank"><button class="btn btn-xs btn-default">'+'<i class="fa fa-file-o" aria-hidden="true"></i>'+'</button></a></span>'+legacy_job_no+link_status;
                    }else{

                        return '<span class="'+label+'" style="'+litify_style+'"><a target="_blank" style="'+purplea+'" ui-sref="diary.editcase.step_one({id: '+ data +'})" href="#/diary/step_one/'+data+'">'+data+'</a></span>'+legacy_job_no+link_status;
                    }
                }),
                //DTColumnBuilder.newColumn('diary_rec.legacy_job_no').withTitle('LegacyJob #').withOption('searchable', true).withClass('job_legacy'),
                DTColumnBuilder.newColumn('diary_rec.DATE_TAKEN').withTitle('Job Date').withOption('defaultContent', '').withOption('searchable', true).withOption('name','diary_rec.dte_taken'),
                DTColumnBuilder.newColumn('diary_time_taken1').withTitle('Start Time').withOption('defaultContent', '').withOption('searchable', true).renderWith(function(data, type, full, meta){
                    if (full.diary_time_taken) {
                        return full.diary_time_taken;
                    } 
                }),                
                DTColumnBuilder.newColumn('diary_rec.End_Time').withTitle('End Time').withOption('defaultContent', '').withOption('searchable', true).notVisible(),    
                DTColumnBuilder.newColumn('diary_rec.timezone_usa.short_name').withTitle('Time Zone').withOption('name', 'diary_rec.timezoneUsa.name').withOption('defaultContent', '').withOption('searchable', false).notVisible().renderWith(function(data, type, full, meta){
                    if (data) {
                        return data;
                    }
                    if(full.diary_rec.timezone_usa){
                        return full.diary_rec.timezone_usa.name;
                    }
                    return '';
                }),    
                DTColumnBuilder.newColumn('diary_rec.job_status.name').withTitle('Job Status').withOption('name', 'diary_rec.jobStatus.name').withOption('defaultContent', '').withOption('searchable', false).renderWith(function(data, type, full, meta){
                    if (data) {
                        let html = data;
                        if($rootScope.StatusIDForEmailAvailable.includes(full.diary_rec.job_status_id)){
                            html += ' <br><a title="Send Job Email" ng-click="sendJobDetailsEmails('+ full.LAST_JOB +','+full.diary_rec.job_status_id+')" >Send Email</a>';
                        }
                        return html;
                    } 
                }),
                DTColumnBuilder.newColumn('CAPTION').withTitle('Caption').withOption('defaultContent', '').withOption('searchable', true).renderWith(function(data, type, full, meta){
                    var cap = '';
                    if (data) {
                        data = escapeString(data);
                        var caption = "<div uib-tooltip='"+data+ "' tooltip-trigger='focus mouseenter' tooltip-placement='top'>"+$scope.limitchar(data,15)+"</div>";
                            caption += '<i style="color: black;">';
                            full.diary_rec.audio_files.forEach(function(item){
                                if(item.filetype == "mp3" || item.filetype == "mp4"){
                                    caption += '<h5>'+item.duration +' - '+ $scope.niceBytes(item.filesize)+'</h5>';
                                }
                            });
                            caption +='<i>';
                            if(full.IndexNumber){
                                caption += "<a href='{{depoHost}}/autologin.php?userid={{auth_user.email}}&password={{auth_user.depo_psd}}&script=display_on_elaw.php?indexNumber="+full.IndexNumber+"' target='_blank' title='Display On Elaw'>Elaw</a></div>";
                            }
                        cap =  caption;
                    } else {
                        cap =  '---';
                    }
                    return cap;
                   
                }),

                DTColumnBuilder.newColumn('FILE_NO').withTitle('File Number').withOption('defaultContent', '').withOption('searchable', true).notVisible(),        
                DTColumnBuilder.newColumn('diary_rec.attorney_firm.LevelOfPayment').withTitle('Client Level').withOption('name', 'diary_rec.attorneyFirm.LevelOfPayment').withOption('defaultContent', '').withOption('searchable', true),
                DTColumnBuilder.newColumn('diary_rec.attorney_firm.SHORT_NAME').withTitle('Scheduling Firm').withOption('name', 'diary_rec.attorneyFirm.SHORT_NAME').withOption('defaultContent', '').withOption('searchable', true).renderWith(function(data, type, full, meta){
                    if (data) {
                        data = escapeString(data);
                        var attorney_firm = "<div uib-tooltip='"+full.diary_rec.attorney_firm.NAME+ "'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>"+$scope.limitchar(data,15)+"</div>";
                        return attorney_firm;
                    } else {
                        return '---';
                    }
                }),
                DTColumnBuilder.newColumn('diary_rec.diary_attorney.name').withTitle('Attorney').withOption('name','diary_rec.diaryAttorney.name').withOption('defaultContent', '').withOption('searchable', true).renderWith(function(data, type, full, meta){
                    if (data) {
                        data = escapeString(data);
                        var attorney_firm = "<div uib-tooltip='"+full.diary_rec.diary_attorney.name+ "'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>"+$scope.limitchar(data,15)+"</div>";
                        return attorney_firm;
                    } else {
                        return '---';
                    }
                }),             
                DTColumnBuilder.newColumn('diary_rec.called_in_by.email').withTitle('Scheduled By').withOption('name', 'diary_rec.calledInBy.email').withOption('defaultContent', '').withOption('searchable', true).renderWith(function(data, type, full, meta){
                    if (data) {
                        data = escapeString(data);
                        var attorney_firm = "<div uib-tooltip='"+full.diary_rec.called_in_by.email+ "'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>"+$scope.limitchar(data,15)+"</div>";
                        return attorney_firm;
                    } else {
                        return '---';
                    }
                }),             
                DTColumnBuilder.newColumn('diary_rec.CON_PHONE').withTitle('FirmCellPhone').withOption('defaultContent', '').withOption('searchable', true),                       
                DTColumnBuilder.newColumn('case_type.name').withTitle('CaseType').withOption('defaultContent', '').withOption('searchable', true).renderWith(function(data, type, full, meta){
                    var ret = '';
                    if(data){
                        var case_type = data.substring(0, 8);
                        ret +=  '<span class="label-warning" uib-tooltip="'+data+'"  tooltip-trigger="focus mouseenter"  tooltip-placement="top" style="border-radius: .25em;padding:3px">'+case_type+'</span>';
                    }                    
                    return ret;
                }),

                DTColumnBuilder.newColumn('diary_rec.job_category.name').withTitle('Job Category').withOption('name', 'diary_rec.jobCategory.name').withOption('defaultContent', '').withOption('searchable', false),

                DTColumnBuilder.newColumn('diary_rec.job_type.name').withTitle('Job Type').withOption('name', 'diary_rec.jobType.name').withOption('defaultContent', '').withOption('searchable', false),       
                DTColumnBuilder.newColumn('witness_type.name').withTitle('Plf/Dft').withOption('defaultContent', '').withOption('searchable', true).renderWith(function(data, type, full, meta){
                    var ret = '';                  
                    if(full.witness_type && full.witness_type.name){
                        var plaintiff_defendent = full.witness_type.name.substring(0, 8);
                        ret +=  '<span class="label-warning" uib-tooltip="'+full.witness_type.name+'"  tooltip-trigger="focus mouseenter"  tooltip-placement="top" style="border-radius: .25em;padding:3px">'+plaintiff_defendent+'</span>';
                    }
                    return ret;
                }),

                DTColumnBuilder.newColumn('euo_statement_of').withTitle('EUO/SOR').withOption('defaultContent', '').withOption('searchable', true).renderWith(function(data, type, full, meta){
                    var cap = '';                    

                    if(full.euo_statement_of){
                        cap = cap+'<a href="javascript:void(0);" uib-tooltip="Toggle" ng-click="toggleJobType('+full.LAST_JOB+',\''+full.euo_statement_of+'\')">'+full.euo_statement_of+'</a>';
                    }
                    return cap;
                   
                }),
                DTColumnBuilder.newColumn('diary_rec.HELD_AT1').withTitle('Job Location').withOption('defaultContent', '').withOption('searchable', true).renderWith(function(data, type, full, meta) {
                    if (data) {
                        data = escapeString(data);
                        var HELD_AT1 = "<div uib-tooltip='"+data+"'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>"+$scope.limitchar(data,15)+"</div>";
                        return HELD_AT1;
                    } else {
                        return '---';
                    }
                }),
                DTColumnBuilder.newColumn('diary_rec.location.name').withTitle('Venue').withOption('defaultContent', '').withOption('searchable', true).renderWith(function(data, type, full, meta) {
                    if (data) {
                        data = escapeString(data);
                        var HELD_AT1 = "<div uib-tooltip='"+data+"'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>"+$scope.limitchar(data,15)+"</div>";
                        return HELD_AT1;
                    } else {
                        return '---';
                    }
                }),

                DTColumnBuilder.newColumn('diary_rec.witness').withOption('name','diary_rec.witness.NAME').withOption('defaultContent', '').withTitle('Witness(es)&nbsp;&nbsp;&nbsp;').withOption('searchable', true).withOption('class', 'reporter-remove-td').renderWith(function(data, type, full, meta){
                    if (data) {
                        let html = '';
                        angular.forEach(data,function(value,key){
                            if(value){
                                html += "<p style='word-break: break-word;'>"+escapeString(value.NAME)+"</p>";
                            }
                        });
                        return html;
                        
                    } else {
                        return '---';
                    }
                }),               


                DTColumnBuilder.newColumn('diary_rec.job_resources').withOption('name','diary_rec.job_resources.resource.FIRST_NAME').withOption('defaultContent', '').withTitle('resources&nbsp;&nbsp;&nbsp;').withOption('searchable', true).withOption('class', 'reporter-remove-td').renderWith(function(data, type, full, meta){
                 
                    var returnClass = "";
                    full.diary_rec.production_queue=='CBU'  ? returnClass = "cbu_job" : returnClass = "";
                    var tableCss = "";
                    full.diary_rec.production_queue=='CBU'  ? tableCss = 'style=" background-color: #ffff6f!important; "' : tableCss = "";
                   
                    html='<div class="'+returnClass+'">';
                    if(full.conflicted_resources != ''){
                        html += '<span style="cursor: pointer;" ng-click="timeoffConflicts(\''+full.conflicted_resources+'\')" class="text-danger"><i class="fa fa-exclamation-circle fa-2x" uib-tooltip="Timeoff - conflicted job"></i></span>';
                    }
                    html +='<table class="table table-bordered" '+tableCss+'>'
                    data.forEach(job_resource => {
                    if(job_resource.resource){
                        var label;
                        var status = '';
                        if(job_resource.resource_type=='R'){
                            var index = full.pending_job_status.findIndex(function (o) {
                                return (o.resource_id == job_resource.resource_id && o.resource_type_id == job_resource.resource_type_id) ;
                            });
                        }
                        else{
                            var index = full.pending_job_status.findIndex(function (o) {
                                return (o.resource_id == job_resource.resource_id) ;
                            });
                        }
                        var status = full.pending_job_status[index].status? full.pending_job_status[index].status.toLowerCase() : '';
                        switch (status) { 
                            case 'sent':
                                label="label label-light-primary";
                                break;
        
                            case 'accepted':
                                label="label label-success";
                                break;
        
                            case 'declined':
                                label="label label-danger";
                                break;
                            default :
                                label="label label-warning";
                                break;
                        }
                        if(job_resource.resource.REP_TYP =='T'){
                            var type ='Typist';
                        }
                        else{
                            var type = (job_resource.additional_resource_type) ? job_resource.additional_resource_type.name :'';
                        }
                        var service_status_name =  null
                        if(job_resource.service_status){
                            if(job_resource.service_status.servicestatus){
                                service_status_name = job_resource.service_status.servicestatus.name
                            }
                        }
                   
                        if(service_status_name){
                                html+= '<tr ><td style="text-align: left !important;width: 50%;"><a uib-tooltip="'+job_resource.resource.name+'-'+type+'"  tooltip-trigger="focus mouseenter"  tooltip-placement="top" class="'+label+'" >'+  $scope.limitchar(job_resource.resource.name,15) +'</a> <span class="label label-danger" ng-click="removeResource('+ full.LAST_JOB +','+job_resource.id+','+job_resource.service_status.job_service_request_id+')" ng-if="havePermission(\'diary\',\'update\')">  <i title="Remove Resource" style="cursor: pointer;" class="fa fa-trash" ></i></span></td><td style="text-align: left !important;width: 50%;"></a> <a class="label label-warning">'+ type+'</a> <a class="label label-light-primary">'+ service_status_name+'</a></p> </td></tr>';    
                        }else{
                                html+= '<tr><td style="text-align: left !important;width: 50%;"><a uib-tooltip="'+job_resource.resource.name+'-'+type+'"  tooltip-trigger="focus mouseenter"  tooltip-placement="top" class="'+label+'" >'+  $scope.limitchar(job_resource.resource.name,15) +'</a> <span class="label label-danger" ng-click="removeResource('+ full.LAST_JOB +','+job_resource.id+')" ng-if="havePermission(\'diary\',\'update\')">  <i title="Remove Resource" style="cursor: pointer;" class="fa fa-trash" ></i></span></td><td style="text-align: left !important;width: 50%;"></a></a> <a class="label label-warning">'+ type+'</a></p></td></tr>';    
                        }   
                        if(job_resource.typist_id){
                            var typist = job_resource.typist;
                            var typist_status =  '';
                            var index = full.pending_job_status.findIndex(function (o) {
                                return (o.resource_id == job_resource.typist_id) ;
                            });
                            var typist_status = full.pending_job_status[index].status? full.pending_job_status[index].status.toLowerCase() : '';
                            switch (typist_status) {
                                case 'sent':
                                    typist_label=" label label-light-primary";
                                    break;
            
                                case 'accepted':
                                    typist_label="label label-success";
                                    break;
            
                                case 'declined':
                                    typist_label="label label-danger"; 
                                    break;
                                default :
                                typist_label="label label-warning";
                                    break;
                            }
                            html+= '<tr><td style="text-align: left !important;width: 50%;"><a uib-tooltip="'+typist.name+'-typist"  tooltip-trigger="focus mouseenter"  tooltip-placement="top" class="'+typist_label+'" >'+  typist.name +'</a></p></td><td style="text-align: left !important;width: 50%;"></a></a> <a class="label label-warning">Typist</a></p></td></tr>';    
                        }
                    }
                    });
                   if(full.diary_rec.service_request && full.diary_rec.service_request.length>0){
                    full.diary_rec.service_request.forEach(element => {
                        var status = ''
                        if(element.service_status && element.service_status.servicestatus && element.service_status.servicestatus.name){
                            status =  element.service_status.servicestatus.name
                        }
                        html+= '<tr><td style="width: 50%;"><span ng-if="havePermission(\'diary\',\'update\')" ng-click="assignSingleResource('+ full.LAST_JOB +','+element.id+','+full.id+')"><i title="Assign Single Resource"  class="fa fa-2x fa-plus" style="cursor: pointer;text-align: center;position: static;"></i></span></td><td style="text-align: left !important;width: 50%;"><a class="label label-warning">'+ element.additional_resource_type.name+'</a> <a class="label label-light-primary">'+ status+'</a> <span class="label label-danger" ng-click="removeResorceService('+ full.LAST_JOB +','+element.id+')" ng-if="havePermission(\'diary\',\'update\')">  <i title="Remove Resource Services" style="cursor: pointer;" class="fa fa-trash" ></i></span></td></tr>';
                    });
                    }
                    if(data.length > 0){
                        html+= '<span ng-if="havePermission(\'diary\',\'update\')" ng-click="assignReporter('+ full.LAST_JOB +',\'reporter\','+full.diary_rec.job_status_id+')"><i title="Change Resource"  class="fa fa-2x fa-pencil" style="cursor: pointer;position: absolute;right: 5px;z-index: 1;top: 12px;font-size: 20px;"></i></span>';    
                    } else{
                        html+= ' </table><span ng-click="assignReporter('+ full.LAST_JOB +',\'reporter\','+full.diary_rec.job_status_id+')" ng-if="havePermission(\'diary\',\'update\')"> <i title="Assign Resource" class="fa fa-2x fa-plus" style="cursor: pointer;"></i></span>';
                    }
                    return html;
                }),
                //Service(s)
                DTColumnBuilder.newColumn(null).withTitle('Send SMS/Email').withOption('defaultContent', '').withOption('searchable', false).withOption('name', 'pending_job_status.status').renderWith(function (data, type, full, meta) {

                    if($rootScope.havePermission('diary','manage.job.confirmation') && full.diary_rec.job_resources.length){
                         return '<a class="" ng-click="sendJobResource(\''+ full.LAST_JOB +'\')">Send Job</a>';
                    }
                    return '';
                }),                
                DTColumnBuilder.newColumn('diary_rec.businessunit.name').withTitle('Business Unit').withOption('searchable', false).withOption('class', 'businessunit').notVisible(),
                DTColumnBuilder.newColumn('diary_rec.covering_businessunit.name').withTitle('CBU').withOption('searchable', false).withOption('class', 'covering_bu').withOption('name', 'diary_rec.covering_businessunit.name').notVisible().renderWith(function (data, type, full, meta) {
                    var html='';
                    if(full.diary_rec.covering_businessunit){
                        html+= full.diary_rec.covering_businessunit.name;
                    }
                    if(full.diary_rec.is_ready_to_release_to_bu=='1'){
                        html+= '<label class="label label-success">Released</label>';
                    }
                    return html;
                }),
                DTColumnBuilder.newColumn('diary_rec.diary_reporting_salesman').withTitle('Account Executive(s)').withOption('name', 'diary_rec.diaryReportingSalesman.reptype.FIRST_NAME').withOption('defaultContent', '').withOption('class', 'account_ex').notVisible().withOption('searchable', true).renderWith(function(data, type, full, meta){                   
                    if (data) {
                        var name = [];
                        angular.forEach(data,function(value,key){
                            if(value.reptype){
                                name[key] = escapeString(value.reptype.FIRST_NAME+' '+value.reptype.LAST_NAME);
                            }
                        });
                        
                        return "<div uib-tooltip='"+name+ "' tooltip-trigger='focus mouseenter' tooltip-placement='top'>"+$scope.limitchar(name,15)+"</div>";
                        
                    } else {
                        return '---';
                    }
                }),
                DTColumnBuilder.newColumn('pending_job_status').withTitle('Action').withOption('searchable', false).withOption('class', 'actions').notSortable().renderWith(function (data, type, full, meta) {

                    var bindButtons = ' <div class="btn-group btn-sm" uib-dropdown>' +
                                            '<button type="button" class="btn btn-primary btn-sm btn-outline" uib-dropdown-toggle> Action <span class="caret"></span></button>'+
                                            '<ul role="menu" uib-dropdown-menu="" style="left: -130px!important;" class="dropdown-menu">';
                                                
                                                if($rootScope.havePermission('diary','update',full.diary_rec.businessunit.id)){
                                                    bindButtons +=  '<li uib-tooltip="Edit">'+
                                                                        '<a class="btn btn-xs btn-default" ui-sref="diary.editcase.step_one({id: '+ full.LAST_JOB +'})"><i class="fa fa-pencil"></i> &nbsp; Edit </a>'+
                                                                    '</li>';                              
                                                }else{
                                                    bindButtons +=  '<li uib-tooltip="View">'+
                                                                        '<a class="btn btn-xs btn-default" ui-sref="diary.editcase.step_one({id: '+ full.LAST_JOB +'})"><i class="fa fa-eye"></i> &nbsp; View Calendar</a>'+
                                                                    '</li>';                        
                                                }
                          
                        var assignedTypistId=[];
                        var assignedTypist=[];
                        angular.forEach(full.diary_rec.job_resources, function (value, key) {
                            if(value.resource_type=='R'){
                                var index = data.findIndex(function (o) {
                                    return (o.resource_id == value.resource_id && o.resource_type_id == value.resource_type_id) ;
                                });
                            }
                            else{
                                var index = data.findIndex(function (o) {
                                    return (o.resource_id == value.resource_id) ;
                                });
                            }
                            var token = data[index] ? data[index].token : '';
                            if(value.resource_type=='R' && value.additional_resource_type.is_typist=='0'){
                                if(value.additional_resource_type && value.resource){
                                if($rootScope.havePermission('diary','manage.job.confirmation')){
                                    bindButtons +=' <li uib-tooltip="View '+value.additional_resource_type.name+'('+ value.resource.name +') Confirmation">'+
                                                            '<a class="btn btn-xs btn-default" target="_blank" href="'+$rootScope.depoHost+'/job_confirmation.php?job_no='+ btoa(full.LAST_JOB) +'&token='+token+'&session='+btoa(value.resource_id)+'&resource_type='+btoa(value.resource_type_id)+'"><i class="fa fa-eye"></i>&nbsp; '+$scope.limitchar(value.resource.name,15)+' - View</a>'+
                                                        '</li>';
                                }else{
                                    bindButtons +=' <li uib-tooltip="View '+value.additional_resource_type.name+'('+ value.resource.name +') Confirmation">'+
                                                        '<a class="btn btn-xs btn-default" target="_blank" href="'+$rootScope.depoHost+'/view_only_confirmation.php?job_no='+ btoa(full.LAST_JOB) +'"><i class="fa fa-eye"></i>&nbsp; '+$scope.limitchar(value.resource.name,15)+' - View</a>'+
                                                    '</li>';                         
                                }
                                }
                            
                            }else if(value.resource_type=='T' || value.additional_resource_type.is_typist=='1'){
                                if($rootScope.havePermission('diary','manage.job.confirmation') && value.resource && (full.diary_rec.assignment_type == 'audio' || full.is_transcript_ordered == 'Y')){
                                    if(full.diary_rec.audio_files.length){
                                        bindButtons +=' <li uib-tooltip="View Typist Confirmation ('+ value.resource.name + ')">'+
                                                            '<a class="btn btn-xs btn-default" target="_blank" href="'+$rootScope.depoHost+'/typist_confirmation.php?job_no='+ btoa(full.LAST_JOB) +'&token='+token+'&session='+btoa(value.resource_id)+'"><i class="fa fa-volume-up"></i>&nbsp; '+$scope.limitchar(value.resource.name,15)+' - View</a>'+
                                                        '</li>';
                                    }else{
                                        bindButtons +=' <li uib-tooltip="Audio files not uploaded ('+ value.resource.name + ')">'+ 
                                            '<a class="btn btn-xs btn-default" href="javascript:void(0);"><i class="fa fa-volume-up"></i><strike>&nbsp; '+$scope.limitchar(value.resource.name,15)+' - View</strike></a>'+
                                        '</li>';
                                    }                              
                                }
                            }
                            if(value.typist && !assignedTypistId.includes(value.typist_id)){
                                assignedTypist.push(value.typist);
                                assignedTypistId.push(value.typist_id);
                            }
                        });
                        angular.forEach(assignedTypist, function (value, key) {
                            var index = data.findIndex(function (o) {
                                return (o.resource_id == value.id) ;
                            });
                            var token = data[index] ? data[index].token : '';                            
                            if($rootScope.havePermission('diary','manage.job.confirmation') && (full.diary_rec.assignment_type == 'audio' || full.is_transcript_ordered == 'Y')){
                                if(full.diary_rec.audio_files.length){
                                    bindButtons +=' <li uib-tooltip="View Typist Confirmation ('+ value.name + ')">'+
                                                        '<a class="btn btn-xs btn-default" target="_blank" href="'+$rootScope.depoHost+'/typist_confirmation.php?job_no='+ btoa(full.LAST_JOB) +'&token='+token+'&session='+btoa(value.id)+'"><i class="fa fa-volume-up"></i>&nbsp; '+$scope.limitchar(value.name,15)+' - View</a>'+
                                                    '</li>';
                                }else{
                                    bindButtons +=' <li uib-tooltip="Audio files not uploaded ('+ value.name + ')">'+ 
                                        '<a class="btn btn-xs btn-default" href="javascript:void(0);"><i class="fa fa-volume-up"></i><strike>&nbsp; '+$scope.limitchar(value.name,15)+' - View</strike></a>'+
                                    '</li>';
                                }                              
                            }
                        });
                        if(full.launch_atic_user && full.launch_atic_user.username && full.launch_atic_user.password && full.is_transcript_ordered == 'N'){
                            var enscript = encodeURIComponent("atic_scheduling.php?job_no="+ full.LAST_JOB);
                            var URL = $rootScope.depoHost +"/autologin.php?userid="+full.launch_atic_user.username+"&password="+full.launch_atic_user.password+"&script="+enscript;
                            bindButtons +=' <li uib-tooltip="Launch atic">'+ 
                                                '<a class="btn btn-xs btn-default" target="_blank" href="'+URL+'"><i class="fa fa-rocket"></i> &nbsp; Launch atic</a>'+
                                            '<li>';
                        }
                        bindButtons += '</ul></div>';

                    return bindButtons;

                })              
            );

        $scope.assignResource = function(jobNo, resource_type){
            var assignResourceModal = $uibModal.open({
                templateUrl: "modules/diary/caselist/assignResource.html",
                controller: 'assignsResourceController',
                resolve: {
                    JOB: function () {
                        return jobNo;
                    },
                    resource_type : function(){
                        return resource_type;
                    }
                },
                keyboard: false,
                backdrop: false,
                close: function () {

                }
            });


            assignResourceModal.result.then(function (cbData) {
            }, function (cbData) {

                if(cbData.isChanged){
                    $scope.reloadData();
                    $rootScope.showLoader(false);
                }

            });
        };


        $scope.assignReporter = function(jobNo, resource_type, job_status_id = null){
            var assignResourceModal = $uibModal.open({
                templateUrl: "modules/diary/caselist/assignReporter.html",
                controller: 'assignReporterController',
                resolve: {
                    JOB: function () {
                        return jobNo;
                    },
                    resource_type : function(){
                        return resource_type;
                    },
                    resources : function(){
                        return null;
                    },
                    formDate : function(){
                        return $scope.formData;
                    }
                },
                size: 'lg',
                keyboard: false,
                backdrop: false,
                close: function () {

                }
            });


            assignResourceModal.result.then(function (cbData) {
            }, function (cbData) {

                if(cbData.isChanged || (job_status_id != cbData.job_status_id)){
                    $scope.updateSingleTableRow(jobNo);
                    // $scope.reloadData();
                    $rootScope.showLoader(false);
                }

            });
        };

        $scope.assignSingleResource = function(jobNo,requestId,caseId){            
            var caseData = $scope.caseData.find(item => item.id === caseId);
            
            if(caseData.diary_rec.service_request){
                var resources = caseData.diary_rec.service_request.find(item => item.id === requestId);
                
                if(resources){
                    var assignResourceModal = $uibModal.open({
                        templateUrl: "modules/diary/caselist/assignSingleResource.html",
                        controller: 'assignSingleResourceController',
                        resolve: {
                            JOB: function () {
                                return jobNo;
                            },
                            resources: function () {
                                return resources;
                            },
                            formDate : function(){
                                return $scope.formData;
                            }
                            
                        },
                        keyboard: false,
                        backdrop: false,
                        close: function () {
        
                        },
                         size: 'lg'
                    });
        
                    assignResourceModal.result.then(function (cbData) {
                    }, function (cbData) {
        
                        if(cbData.isChanged){
                            $scope.updateSingleTableRow(jobNo);
                            // $scope.reloadData();
                            $rootScope.showLoader(false);
                        }
        
                    });
                }
            }
         
        };
        $scope.removeResorceService = function(job_no,id){
            swal({
                title: "Are you sure",
                text: "you want to remove resource service?",
                type: "warning",
                html: true,
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) { 
                    $rootScope.showLoader(true);
                    commonFactory.post('/api/removeResorceService', { id : id, JOB_NO : job_no })
                        .success(function (response) {
                            if (response && response.result) {
                               
                                $scope.updateSingleTableRow(job_no);
                                $rootScope.showLoader(false);
                                Notification.success("Resource Service Remove Succesfully");
                              
                            } else {
                                $rootScope.showLoader(false);
                            }
                        })
                        .error(function (err) {
                            $rootScope.showLoader(false);
                            console.log(err);
                        });
                }
            });
        }
        $scope.removeResource = function(job_no,id,ServiceRequestId=null){
            var removeResourceServiceRequestId = [];
            if(ServiceRequestId){
                removeResourceServiceRequestId.push(ServiceRequestId);
            }
            swal({
                title: "Are you sure",
                text: "you want to remove resource ?",
                type: "warning",
                html: true,
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) { 
                    $rootScope.showLoader(true);
                    commonFactory.post('/api/removeResource', { id : id, JOB_NO : job_no ,removeResourceServiceRequestId:removeResourceServiceRequestId})
                        .success(function (response) {
                            if (response && response.result) {
                                if(response.error == true){
                                    $rootScope.showLoader(false);
                                    Notification.error(response.result.message);
                                } else{
                                $scope.updateSingleTableRow(job_no);
                                $rootScope.showLoader(false);
                                Notification.success("Resource Remove Succesfully");
                                }
                              
                            } else {
                                $rootScope.showLoader(false);
                            }
                        })
                        .error(function (err) {
                            $rootScope.showLoader(false);
                            console.log(err);
                        });
                }
            });
        }
        // $scope.removeResource = function(JOB_NO, resource_type){

        //     SweetAlert.swal({
        //             title: "Are you sure ?",
        //             text: "You are about to remove a "+resource_type,
        //             type: "warning",
        //             showCancelButton: true,
        //             confirmButtonColor: "#09375f",
        //             confirmButtonText: "Yes, Do it",
        //             cancelButtonText: "No!",
        //             closeOnConfirm: true,
        //             closeOnCancel: true
        //         }, function(callback){
        //                 if(callback){
        //                 $rootScope.showLoader(true);
        //                 return $http.post(apiUrl + '/api/removeResource', {JOB_NO : JOB_NO, resource_type : resource_type})
        //                 .then(function(response) {
        //                     Notification.success(response.data.message);
        //                     $scope.reloadData();
        //                     $rootScope.showLoader(false);
        //                 });
        //              }
        //         });
        // };

        $scope.sendJobSms = function(jobNo, status, cellPhone, REP_NAME_KEY, reporter_id, token){

            var textMsg = 'Depositionnet assignment - confirm or decline at:';
            status = status ? status.toLowerCase() : '';
            switch (status) {
                case 'sent':
                    textMsg = 'Depositionnet assignment - you have not replied:';
                    break;

                case 'accepted':
                    textMsg = 'Depositionnet confirmed. Changes:';
                    break;

                case 'declined':
                    textMsg = 'Depositionnet declined. Changes:';
                    break;
                default :
                    break;
            }
            var sendJobSmsModal = $uibModal.open({
                templateUrl: "modules/diary/caselist/sendJobSms.html",
                controller: function($scope, SMSDATA, $uibModalInstance){

                    $scope.title = "Send SMS";
                    $scope.formData = SMSDATA;

                    $scope.cancel = function(isChanged) {

                        $uibModalInstance.dismiss({ isChanged : isChanged});
                    };

                    $scope.sendSms = function(isValid){
                        if(isValid){
                            $rootScope.showLoader(true);
                            commonFactory.post('/api/sendJobSms', $scope.formData)
                                .success(function(response){
                                    $rootScope.showLoader(false);
                                    if(response.error){
                                        Notification.error(response.result.message || "Something went wrong while sending SMS!");
                                    } else{
                                        $uibModalInstance.dismiss({ isChanged : true});
                                        Notification.success(response.result.message || "SMS successfully sent.");
                                    }
                                }).error(function(error){
                                    $rootScope.showLoader(false);
                                    Notification.error("Something went wrong while sending SMS!");

                                });
                        }
                    }
                },
                resolve: {
                    SMSDATA: function () {
                        return {
                            JOB_NO: jobNo,
                            status: status,
                            CELL: cellPhone,
                            REP_NAME_KEY: REP_NAME_KEY,
                            textMsg: textMsg + " \n" + $rootScope.depoHost+"/job_confirmation.php?job_no="+ btoa(jobNo)+'&token='+token+'&session='+btoa(reporter_id),
                            URL: $rootScope.depoHost+"/job_confirmation.php?job_no="+ btoa(jobNo)+'&token='+token+'&session='+btoa(reporter_id),
                            reporter_id : reporter_id,
                            token : token
                        };
                    }
                },
                keyboard: false,
                backdrop: false,
                close: function () {

                }
            });


            sendJobSmsModal.result.then(function (cbData) {
            }, function (cbData) {

                if(cbData.isChanged){
                    $scope.reloadData();
                    $rootScope.showLoader(false);
                }

            });
        };

        $rootScope.sendJobEmail = function(jobNo, status, emailAddress, TYP_NAME_KEY, typist_id, token){

            var emailMsg = 'Depositionnet assignment - confirm or decline at:';
            status = status ? status.toLowerCase() : '';
            switch (status) {
                case 'sent':
                    emailMsg = 'Depositionnet assignment - you have not replied:';
                    break;

                case 'accepted':
                    emailMsg = 'Depositionnet confirmed. Changes:';
                    break;

                case 'declined':
                    emailMsg = 'Depositionnet declined. Changes:';
                    break;
                default :
                    break;
            }
            var sendJobEmailModal = $uibModal.open({
                templateUrl: "modules/diary/caselist/sendJobEmail.html",
                controller: function($scope, email_data, $uibModalInstance, codeFactory){

                    $scope.title = "Send Email";
                    $scope.formData = email_data;
                    $scope.validations = codeFactory.validations;
                    $scope.messages = codeFactory.messages;

                    $scope.cancel = function(isChanged) {

                        $uibModalInstance.dismiss({ isChanged : isChanged});
                    };

                    $scope.sendEmail = function(isValid){
                        
                        if(isValid){
                            $rootScope.showLoader(true);
                            commonFactory.post('/api/sendJobEmail', $scope.formData)
                                .success(function(response){
                                    $rootScope.showLoader(false);
                                    if(response.error){
                                        Notification.error(response.result.message || "Something went wrong while sending Email!");
                                    } else{
                                        $uibModalInstance.dismiss({ isChanged : true});
                                        Notification.success(response.result.message || "Email successfully sent.");
                                    }
                                }).error(function(error){
                                    $rootScope.showLoader(false);
                                    Notification.error("Something went wrong while sending Email!");

                                });
                        }
                    }
                },
                resolve: {
                    email_data: function () {
                        return {
                            JOB_NO: jobNo,
                            status: status,
                            email_address: emailAddress,
                            TYP_NAME_KEY: TYP_NAME_KEY,
                            emailMsg: emailMsg + " \n " + $rootScope.depoHost+"/typist_confirmation.php?job_no="+ btoa(jobNo)+'&token='+token,
                            URL: $rootScope.depoHost+"/typist_confirmation.php?job_no="+ btoa(jobNo)+'&token='+token,
                            typist_id : typist_id,
                            token : token
                        };
                    }
                },
                keyboard: false,
                backdrop: false,
                close: function () {

                }
            });

            sendJobEmailModal.result.then(function (cbData) {
            }, function (cbData) {
                if(cbData.isChanged){ 
                    $scope.reloadData();
                    $rootScope.showLoader(false); 
                }
            });
        };

        $scope.sendJobResource=function(job){
            var sendJobEmailModal = $uibModal.open({
                templateUrl: "modules/diary/caselist/sendJobResource.html",
                controller:'sendJobResourceController',
                resolve: {
                    jobNo: function () {
                        return job
                    }
                },
                keyboard: false,
                backdrop: false,
                close: function () {

                }
            });

            sendJobEmailModal.result.then(function (cbData) {
            }, function (cbData) {
                if(cbData.isChanged){ 
                    $scope.updateSingleTableRow(job);
                    // $scope.reloadData();
                    $rootScope.showLoader(false); 
                }
            });
        }

        $scope.updateSingleTableRow = function(job_no){

            var rows = $scope.dtInstance.DataTable.rows().data();

            var filter = rows.filter(function(obj, index) {
                return obj.LAST_JOB == job_no;
            })[0];

            var index = rows.indexOf(filter);
            if(index != -1){
                let findcase = {};
                findcase.searchValue = job_no;
                findcase.selectedValue = 'LAST_JOB';

                commonFactory.post('/api/casexists', findcase)
                .success(function(response) {                    
                    $rootScope.showLoader(false);
                    if (response && response.data && response.data.length > 0) {                      
                        
                        var temp = $scope.dtInstance.DataTable.row(index).data();
                        temp = response.data[0];
                        $scope.dtInstance.DataTable.row(index).data(temp).invalidate();          
                        $scope.createdRow($scope.dtInstance.DataTable.row(index).node());        
                        // $('#DataTables_Table_0').dataTable().fnUpdate(temp,index,undefined,false);
                        // $scope.dtInstance.DataTable.row(0).data(temp).invalidate();
                    }else{
                        $scope.reloadData();
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
            }else{
                $scope.reloadData();
            }
        }

        $scope.toggleJobType = function(JOB_NO, euo_statement_of){
            
            SweetAlert.swal({
                title: "Are you sure want to change?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes, Change it",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            }, function(callback){
                if(callback){
                    $rootScope.showLoader(true);
                    return $http.post(apiUrl + '/api/toggle-job-type', {JOB_NO : JOB_NO, euo_statement_of : euo_statement_of})
                    .then(function(response) {
                        $scope.reloadData();
                        $rootScope.showLoader(false);
                    });
                }
            });
        };
        $scope.sendNewAssignmentReceipt = function(JOB_NO,link){
            swal({
                title: "Are you sure?",
                text: "You about to re-send assignment receipt.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm){
                if(isConfirm){
                    $rootScope.showLoader(true);   
                    $http.post(apiUrl+'/api/send-new-assignment-receipt',{JOB_NO : JOB_NO,link:link }).success(function(res){
                        $rootScope.showLoader(false);
                        if(res.error){
                            Notification.error(res.result.message);
                        }else{
                            Notification.success(res.result.message);
                        }
                    }).error(function(err){
                        $rootScope.showLoader(false);
                        Notification.error(err);
                    });
                }
            });
        }
        $scope.sendJobDetailsEmails = function(jobNo, status){

            $uibModal.open({
               templateUrl: "modules/diary/caselist/sendJobDetailsEmail.html",
               controller: function($scope, email_data, $uibModalInstance, codeFactory){

                   $scope.title = "Send Email";
                   $scope.formData = email_data;
                   $scope.validations = codeFactory.validations;
                   $scope.messages = codeFactory.messages;
                   $rootScope.showLoader(true);
                   commonFactory.post('/api/get-send-job-confirmation-trigger-to-email', $scope.formData)
                       .success(function(response){
                            if(response.email_type){
                                $scope.title += ' ('+response.email_type+')';
                            }
                           response = response.result;
                           $rootScope.showLoader(false);
                           if($scope.formData.status == 1 ||$scope.formData.status == 2){
                               if(response && response.diary_attorney ){
                                   if(response.diary_attorney.attorney_permission){
                                       if(response.diary_attorney.email  && response.diary_attorney.attorney_permission.advance_input == 'Y'){
                                           $scope.formData.email_address = response.diary_attorney.email
                                           if(response.diary_attorney.attorney_permission.designees_2){
                                               $scope.formData.email_address = $scope.formData.email_address+',' +response.diary_attorney.attorney_permission.designees_2

                                           }
                                       }
                                       else {
                                           
                                           $scope.formData.email_address =response.diary_attorney.attorney_permission.designees_2?response.diary_attorney.attorney_permission.designees_2:''
                                       }
                                   }
                               }
                           }else{
                               if(response && response.diary_attorney ){
                                   if(response.diary_attorney.attorney_permission){
                                       if(response.diary_attorney.email  && response.diary_attorney.attorney_permission.trigger_day_before == 'Y'){
                                           $scope.formData.email_address = response.diary_attorney.email;
                                           if(response.diary_attorney.attorney_permission.designees_3){
                                               $scope.formData.email_address = $scope.formData.email_address+',' +response.diary_attorney.attorney_permission.designees_3.split(';').join(',');
                                           }
                                       }
                                       else {
                                           $scope.formData.email_address =response.diary_attorney.attorney_permission.designees_3?response.diary_attorney.attorney_permission.designees_3:''
                                       }
                                   }
                               }
                           }
                       }).error(function(error){
                           $rootScope.showLoader(false);
                          

                       });
                   $scope.cancel = function(isChanged) {

                       $uibModalInstance.dismiss({ isChanged : isChanged});
                   };

                   $scope.sendEmail = function(isValid){
                       
                       if(isValid){
                           $rootScope.showLoader(true);
                           commonFactory.post('/api/SendJobConfirmationTriggerToEmail', $scope.formData)
                               .success(function(response){
                                   $rootScope.showLoader(false);
                                   if(response.error){
                                       Notification.error(response.result.message || "Something went wrong while sending Email!");
                                   } else{
                                       $uibModalInstance.dismiss({ isChanged : true});
                                       Notification.success(response.result.message || "Email successfully sent.");
                                   }
                               }).error(function(error){
                                   $rootScope.showLoader(false);
                                   Notification.error("Something went wrong while sending Email!");

                               });
                       }
                   }
               },
               resolve: {
                   email_data: function () {
                       return {
                           JOB_NO: jobNo,
                           status: status,
                          
                       };
                   }
               },
               keyboard: false,
               backdrop: false,
               close: function () {

               }
           });

          
       };

        $scope.niceBytes = function (x){
            
            const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

            if(x === 1) return '1 byte';

              let l = 0, n = parseInt(x, 10) || 0;

              while(n >= 1000 && ++l){
                  n = n/1000;
              }
              //include a decimal point and a tenths-place digit if presenting
              //less than ten of KB or greater units
              return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
        };

        $scope.limitchar = function (input, limit){
            if (limit > input.length) {
                return input.slice(0, limit);
            } else {
                return input.slice(0, limit) + '...';
            }
        }



        $scope.showhideToggle = function () {
            $element =$('#diaryFilter');
            var ibox = $element.closest('div.ibox');
            var icon = $element.find('i:first');
            var content = ibox.find('div.ibox-content');
            content.slideToggle(200);
            // Toggle icon from up to down
            icon.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
            ibox.toggleClass('').toggleClass('border-bottom');
            $timeout(function () {
                ibox.resize();
                ibox.find('[id^=map-]').resize();
            }, 50);
        };

        $scope.showFirmInvoices = function(att_id, firm_name){
            invoiceFactory.searchData = {
                searchValue : {
                    id : att_id,
                    NAME: firm_name
                },
                selectedValue : "BILLING_FIRM"
            };

            $state.go('invoice.browse');
        };

    }]);
