angular.module('deitz').controller('editadditionalinfoController', [
    '$scope', '$state', '$rootScope', 'codeFactory', 'commonFactory', 'Notification', 'fromModal', 'plaintiffId', '$uibModalInstance',
    function(
    $scope, $state, $rootScope, codeFactory, commonFactory, Notification, fromModal, plaintiffId, $uibModalInstance
    ) {

        $scope.additionalinfo = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Edit RESOURCE TYPES';
        $scope.modalTitle = 'Edit plaintiff/defendant';
        $scope.modalId = plaintiffId;

        //close modal
        $scope.closeModal = function(){
            $uibModalInstance.close();
        };

        $scope.resourceSkillsList = []

        // Getting all resources skills.
        commonFactory.get('/api/resource-skills')
        .success(function(response) {
            if(response.error) {
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.resourceSkillsList = response.result.resourcesSkills;
            }
        });

        commonFactory.get('/api/service-type-list').then(function(response) { 
            $scope.service_type_list = response.data.result.data;  
            $scope.service_type_list = [{id:null,name:"None"}].concat($scope.service_type_list)

        });

        //Edit Mode of additionalinfo.
        if ($state.params && $state.params.id) {
            var id = plaintiffId? plaintiffId:$state.params.id;
            commonFactory.get('/api/additionalinfo/' + id + '/edit')
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if (response.status == 200 && response.result && response.result.additionalinfo_detail) {
                        $scope.additionalinfo.name = response.result.additionalinfo_detail.name || '';
                        $scope.additionalinfo.is_default = response.result.additionalinfo_detail.is_default || 0;
                        $scope.additionalinfo.service_type_id = response.result.additionalinfo_detail.service_type_id
                        $scope.additionalinfo.is_typist = response.result.additionalinfo_detail.is_typist
                        
                        if(response.result.additionalinfo_detail.resource_skills_ids && response.result.additionalinfo_detail.resource_skills_ids.length){
                            var resourceSkillsIds = [];
                            angular.forEach(response.result.additionalinfo_detail.resource_skills_ids,function(value, key){
                                resourceSkillsIds[key] = value.resource_skill_id;
                            });
                            $scope.additionalinfo.resource_skills_ids = resourceSkillsIds;
                        }

                        // $scope.additionalinfo.slug = response.result.additionalinfo_detail.slug || '';
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
        }
 
        //Update Mode of additionalinfo
        $scope.addAdditionalInfo = function(additionalinfo, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                var id = plaintiffId? plaintiffId:$state.params.id;
                commonFactory.put('/api/additionalinfo/' + id, $scope.additionalinfo)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("AdditionalInfo updated successfully");
                                if(fromModal == true){ 
                                    $uibModalInstance.close(response.result.additionalinfo_update.id);
                                }else{
                                    $state.go("code_tables.additionalinfo_browse");
                                }
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }]);
