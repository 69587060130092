angular.module('deitz').controller('addfirmsController', [
    '$scope','$rootScope','$state','firmFactory','Notification','$stateParams','commonFactory','$uibModal','$uibModalInstance', 'apiUrl','$http','elawId',
    function(
        $scope, $rootScope, $state, firmFactory, Notification, $stateParams, commonFactory, $uibModal, $uibModalInstance, apiUrl, $http, elawId
    ){
        $scope.formData = {
            regular_other : null,
            show_calculations :'no',
            send_job_confirmation_to : 'single',
            contract :'no'
        };
        $scope.frequency = {
            email_fax : 'ef_set_frequency',
            statement_invoice: 's'
        };
        $scope.firmData = {};
        $scope.preferenceFormData = {};
        $scope.isEditMode=($state.params.id)? true : false;
        $scope.validations = firmFactory.validations;
        $scope.messages = firmFactory.messages;
        $scope.title = 'Add Firm';
        $scope.signupForm = false;
        $scope.openEBTTab = false;
        $scope.formData.sales_rep_dte_assigned = '';
        $scope.translator = [{ id: null, company_name: 'Please select Translator' }];


        $scope.rates = [
            {
                id : "R",
                name : "CLIENT'S DEFAULT"
            },
            {
                id : "O",
                name : 'OTHER'
            }
        ];
        $scope.formData.phone = firmFactory.sharedPhonenumber ? firmFactory.sharedPhonenumber : "";
        $scope.formData.firm_name = firmFactory.sharedName ? firmFactory.sharedName : "";
        $scope.formData.address1 = firmFactory.sharedAddress ? firmFactory.sharedAddress : "";
        $scope.formData.attorney_rate_details = [];
        $scope.openChat = false;
        $scope.formData.beeper = 'none';
        $scope.salesperson_source_locked = false;
        $scope.formData.shipping_diff = 'N';
        $scope.$watch('formData.shipping_diff', function (newValue, oldValue) {
            if (newValue === 'N'){
                $scope.formData.shipping_address1 = $scope.formData.address1
                $scope.formData.shipping_address2 = $scope.formData.address2
                $scope.formData.shipping_city = $scope.formData.city
                $scope.formData.shipping_state = $scope.formData.state
                $scope.formData.shipping_zip = $scope.formData.zip
            }
        });
        $scope.$watch('formData.address1', function (newValue, oldValue) {
            $scope.changeValues('shipping_address1', newValue);
        });
        $scope.$watch('formData.address2', function (newValue, oldValue) {
            $scope.changeValues('shipping_address2', newValue);
        });
        $scope.$watch('formData.city', function (newValue, oldValue) {
            $scope.changeValues('shipping_city', newValue);
        });
        $scope.$watch('formData.state', function (newValue, oldValue) {
            $scope.changeValues('shipping_state', newValue);
        });
        $scope.$watch('formData.zip', function (newValue, oldValue) {
            $scope.changeValues('shipping_zip', newValue);
        });

        $scope.changeValues = function (key, newValue) {
            if ($scope.formData.shipping_diff === 'N') {
                $scope.formData[key] = newValue;
            }
        }
        
        // Getting all sources from source table.
        $http.get(apiUrl + '/api/get_source').then(function(response) { 
            $scope.attorneySources=response.data.data;
            // angular.forEach($scope.attorneySources, function(value, key) { 
            //     if(value.isDefault == 1){
            //         $scope.formData.source = value.id;
            //     }
            // });   
        });
        //Getting all Translator.
        $scope.refreshTranslator = function () {
            $rootScope.showLoader(true);
            commonFactory.get('/api/translators')
                .success(function (response) {
                    $rootScope.showLoader(false);
                    if (response.result && response.result.translator) {
                        $scope.translator = $scope.translator.concat(response.result.translator || []);
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                });
        };
        $scope.refreshTranslator();

        commonFactory.post('/api/getInsuranceCompany',{fields:['id', 'CompanyName', 'Address1', 'City']})
        .success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.InsuranceCompanyList = response.result.data;
            }
            $rootScope.showLoader(false);
        });

        // Getting all statement frequency
        $http.get(apiUrl + '/api/get-statement-codes').success(function(res){
            $scope.statement_codes = res.data;
        }); 

        //get all BussinesUnit for diary
        if (!($scope.BusinessUnitsList && $scope.BusinessUnitsList.length)) {
            $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
                $scope.BusinessUnitsList = response.data.result.data;
            });   
        }

        $http.get(apiUrl + '/api/rates/getFirmRateList').then(function(response) { 
            $scope.RateList = response.data.result.data;  
        });
        
        $scope.formData.source = '';
        $scope.formData.business_unit_id = '';
        //get all BussinesUnit for diary
        $http.get(apiUrl + '/api/get-user-roles-businessunit').then(function(response) { 
            $scope.rolesWithBusinessUnits = response.data.data;  
            // $scope.formData.business_unit_id = $scope.rolesWithBusinessUnits[0].business_unit_id
        });

        // Getting all terms 
        $http.post(apiUrl + '/api/getAllterms').success(function(res){
            $scope.firm_terms = res.data;
            $scope.formData.terms = 16;
        });
        // Getting all rates from rates table.
        commonFactory.get('/api/rate/getActiveRate')
            .success(function(response) { 
                if (response.result && response.result.rates) {
                    $scope.insuranceRates = response.result.rates;
                }
            })
            .error(function(err) {});

        // Getting all rates card group
        $scope.refreshRateCardGroup = function () {
            $rootScope.showLoader(true);
            commonFactory.get('/api/rateCardGroups').success(function (response) {
                if (response.result) {
                    $scope.rateCardGroups = response.result.rateCardGroup;
                }
                $rootScope.showLoader(false);
            }).error(function (err) {
                $rootScope.showLoader(false);
            });
        }
        $scope.refreshRateCardGroup();

        $scope.$watch('formData.rate_card_group', function (newValue, oldValue) {
            if ((newValue && oldValue) && (newValue.length < oldValue.length)) {
                let diff = oldValue.filter((val) => !newValue.some((e) => e === val));
                let newArray = $scope.insuranceRates.filter((insuranceRate) => diff.indexOf(insuranceRate.rate_card_group_id) !== -1)
                newArray = newArray.map(rate => rate.InsuranceRateID);
                $scope.formData.attorney_rate_details = $scope.formData.attorney_rate_details.filter(function (el) {
                    return newArray.indexOf(el) < 0;
                });
            }
        });

        $scope.groupChange = function () {
            // Get rates based on rate group
            commonFactory.post('/api/rate/getRateGroupActiveRate', {rate_card_group: $scope.formData.rate_card_group})
                .success(function (response) {
                    if (response.result && response.result.rates) {
                        const rates = response.result.rates.map(rate => rate.InsuranceRateID);
                        $scope.formData.attorney_rate_details = [...new Set([...$scope.formData.attorney_rate_details, ...rates])];
                    }
                })
                .error(function (err) {
                });

        };

        if($state.params.elawId || elawId){
            $scope.elawId = elawId || $state.params.elawId;
            firmFactory.getAttorneyFromElaw('/api/elaw/attorney', { id : $scope.elawId}).success(function(response){
                var fullAddressArr = response.data.address.split(',');

                $scope.formData.firm_name= response.data.attorney;
                $scope.formData.address1= (fullAddressArr.length > 0) ? fullAddressArr[0].trim(): "";
                $scope.formData.address2= (fullAddressArr.length === 4) ? fullAddressArr[1].trim(): "";
                $scope.formData.city= (fullAddressArr.length ) ? fullAddressArr[fullAddressArr.length - 2].trim(): "";
                $scope.formData.state= (fullAddressArr.length ) ? fullAddressArr[fullAddressArr.length - 1].replace(/\s/g,''): "";
                $scope.formData.phone= response.data.phone;
                $scope.formData.zip= response.data.zip;
            });
        }

        $scope.grades_arr = [];
        /*Use for get grades */
        $http.get(apiUrl + '/api/get_grades').then(function(response) {            
            $scope.grades_arr = response.data.data;            
        });

        $scope.LevelOfPayment_arr = [];

        /*Use for get client level */
        $http.get(apiUrl + '/api/get_client_level').then(function(response) {            
            $scope.LevelOfPayment_arr = response.data.data;            
        });
        $scope.firm_designation_arr, $scope.firm_group_arr, $scope.firm_sort1_arr, $scope.firm_sort2_arr  = [];
         /*Use for get firm Designation, group and sort */
         $http.get(apiUrl + '/api/get_designation_group_sort').then(function(response) {    
            $scope.firm_designation_arr = response.data.firm_designation;
            $scope.firm_group_arr = response.data.firm_group;
            $scope.firm_sort1_arr = response.data.firm_sort1;
            $scope.firm_sort2_arr = response.data.firm_sort2;            
         });
        
        // Get the auto Partner
        commonFactory.get('/api/config/get-auto-partner').success(function (response) {
            if (response.result) {
                $scope.auto_partners = response.result;
            }
        });

        $scope.refreshFirmtype = function () {
            $rootScope.showLoader(true);
            commonFactory.get('/api/firmTypes').success(function (response) {
                if (response.result) {
                    $scope.firmTypes = response.result.firmtype;
                }
                $rootScope.showLoader(false);
            }).error(function (err) {
                $rootScope.showLoader(false);
            }); 
        };
        
        $scope.refreshFirmtype();
        
        $scope.refreshFirmAddressType = function(){
            $rootScope.showLoader(true);
            commonFactory.get('/api/firmAddressTypes').success(function (response) {
                if (response.result) {
                    $scope.firmAddressTypes = response.result.firmAddressType;
                }
                $rootScope.showLoader(false);
            }).error(function (err) {
                $rootScope.showLoader(false);
            }); 
        }
        $scope.refreshFirmAddressType();
        
        // Getting all Collectors 
        $http.get(apiUrl + '/api/collector').success(function(res){
            $scope.collectors = res.result;
        });
        
        // Getting all invoice and transcript handling type 
        $http.get(apiUrl + '/api/invoice-and-transcript-handling').success(function(res){
            $scope.invoice_and_transcript_handling_methods = res.result;
        });
             
        $scope.searchClaimRepName = function (val) {
            return $http.post(apiUrl + '/api/search-claimrep-name', { query_string: val }).then(function (response) {
                return response.data.result.map(function (item) {
                    return item;
                });
            });
        };

        $scope.goto_rate = function() {
            if(angular.equals({}, $uibModalInstance)) {
                $state.go('firms.add.step_two_add');
            }else{
                $scope.activeTab = 'tab-2';
            }
        };

        $scope.goto_preference = function() {
            if(angular.equals({}, $uibModalInstance)) {
                $state.go('firms.add.preference_add');
            }else{
                    $scope.activeTab = 'tab-3';
            }
        };

        $scope.goto_other = function() {
            if(angular.equals({}, $uibModalInstance)) {
                $state.go('firms.add.step_three_add');
            }else{
                    $scope.activeTab = 'tab-4';
            }
        };

        $scope.goto_address = function() {
            if(angular.equals({}, $uibModalInstance)) {
                $state.go('firms.add.step_one_add');
            }else{
                $scope.activeTab = 'tab-1';
            }
        };

        if($state.current.name !== 'firms.add.step_one_add' && $scope.formData.firm_name==''){
            // redirect only if add firm is not opend in modal
            if(angular.equals({}, $uibModalInstance)){
                $state.go('firms.add.step_one_add');
            }
        }

        $scope.attorneyofficedata = function(val) {
            return $http.get(apiUrl + '/api/getattorneyfirm', {
                params: {
                    name: val,
                    parentFirm: $scope.formData.parent_firm ? $scope.formData.parent_firm.id : ''
                }
            }).then(function(response) {
                $scope.foundFromElaw = false;
                if(response.data.foundFromElaw){
                    $scope.foundFromElaw = true;
                }
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        $scope.corporateclientdata = function(val) {
            return $http.get(apiUrl + '/api/getattorneyfirm', {
                params: {
                    name: val,
                    conporateClient: 1
                }
            }).then(function(response) {
                $scope.foundFromElaw = false;
                if(response.data.foundFromElaw){
                    $scope.foundFromElaw = true;
                }
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        $scope.TeamMemberData = function(val) {
            return $http.get(apiUrl + '/api/get-user-lists', {
                params: {
                    name: val,
                }
            }).then(function(response) {
                $scope.foundFromElaw = false;
                if(response.data.foundFromElaw){
                    $scope.foundFromElaw = true;
                }
                return response.data.result.map(function(item) {
                    item.lable = item.name +'-'+ item.email
                    return item;
                });
            });
        };

        commonFactory.get('/api/all-active-collection-policy-exceptions').success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.collectionPolicyExceptionData = response.result.collectionPolicyException;
            }
        });

        commonFactory.get('/api/all-active-format-exhibits').success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.formatExhibitData = response.result.formatExhibit;
            }
        });

        commonFactory.get('/api/all-active-format-transcripts').success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.formatTranscriptData = response.result.formatTranscript;
            }
        });

        commonFactory.get('/api/all-active-format-videos').success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.formatVideoData = response.result.formatVideo;
            }
        });

        commonFactory.get('/api/all-active-format-statements').success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.formatStatementData = response.result.formatStatement;
            }
        });

        commonFactory.get('/api/all-active-remote-connection-preferences').success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.remoteConnectionPreferenceData = response.result.remoteConnectionPreference;
            }
        });

        commonFactory.get('/api/getLocation').success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.preferredLocationData  = response.result.data;
                $scope.unpreferredLocationData  = response.result.data;
            }
        });

        // Store firm information (STORE)
        $scope.save = function() { 
            $scope.signupForm = true;
            
            if($scope.frequency.email_fax == 'ef_set_frequency'){

                $scope.formData.statement_schedules_id = null;
                
            }else{
                //set statement frequency code from radio button combination
                angular.forEach($scope.statement_codes,function(value,key){
                    if(value.email_fax == $scope.frequency.email_fax && value.statement_invoice == $scope.frequency.statement_invoice){
                        $scope.formData.statement_schedules_id = Number(value.id);
                    }
                }); 
            }
            if ($scope.formData && $scope.formData.phone) {
                $scope.formData.phone = $scope.formData.phone.replace(/[()]+/g, '') || "";
            }

            $scope.formData.parent_firm_id = $scope.formData.parent_firm && $scope.formData.parent_firm.id ? $scope.formData.parent_firm.id : null;
                
            if ($scope.formData.child_firms && $scope.formData.child_firms.length > 0) {
                $scope.formData.child_firm_ids = $scope.formData.child_firms.map((firm) => { 
                    return firm.id 
                });   
            }
            if ($scope.formData.corporate_clients && $scope.formData.corporate_clients.length > 0) {
                $scope.formData.corporate_client_ids = $scope.formData.corporate_clients.map((corporate_client) => { 
                    return corporate_client.id 
                });   
            }

            $scope.formData.sales_rep_dte_assigned = $scope.formData.sales_rep_dte_assigned ? moment($scope.formData.sales_rep_dte_assigned ).format('YYYY/MM/DD') : null;
            $scope.formData.sales_rep_dte_assigned = $scope.formData.sales_rep_dte_assigned == 'Invalid date' ? null : $scope.formData.sales_rep_dte_assigned;
            $scope.formData.receive_cc = $scope.formData.receive_cc ? $scope.formData.receive_cc : 'N';
            $scope.formData.nowait = $scope.formData.nowait ? $scope.formData.nowait : 'N';
            
            $scope.formData.auto_attach = $scope.formData.auto_attach ? $scope.formData.auto_attach : 'N';
            $scope.formData.auto_attach_invoice = $scope.formData.auto_attach_invoice ? $scope.formData.auto_attach_invoice : 'N';
            $scope.formData.smartadvocate_host = $scope.formData.smartadvocate_host ? $scope.formData.smartadvocate_host : '';
            $scope.formData.depo_attorney_office = $scope.formData.depo_attorney_office ? $scope.formData.depo_attorney_office : '';
            $scope.formData.is_claim_info_required = $scope.formData.is_claim_info_required ? $scope.formData.is_claim_info_required : 'N';

            $scope.formData.is_feature_no_required = $scope.formData.is_feature_no_required ? $scope.formData.is_feature_no_required : 'N';
            $scope.formData.is_firm_file_no_required = $scope.formData.is_firm_file_no_required ? $scope.formData.is_firm_file_no_required : 'N';
            $scope.formData.is_insurance_file_no_required = $scope.formData.is_insurance_file_no_required ? $scope.formData.is_insurance_file_no_required : 'N';
            $scope.formData.is_insurance_claim_no_required = $scope.formData.is_insurance_claim_no_required ? $scope.formData.is_insurance_claim_no_required : 'N';
            $scope.formData.append_invoice_with_proof_of_assignment = $scope.formData.append_invoice_with_proof_of_assignment ? $scope.formData.append_invoice_with_proof_of_assignment : 'N';
            
            angular.forEach($scope.firm_terms,function(value, key){
                if(value.id == $scope.formData.terms){

                    $scope.formData.TERMS = value.name;
                }
            });
            
            firmFactory.save($scope.formData)
                .success(function(response) {
                    // $rootScope.showLoader(false);
                    if (response && response.result && response.result.data && response.result.data.id) {
                        $scope.firmData = response.result.data;
                        var state_id = response.result.data.id;

                        if ($scope.preferenceFormData.preferred_team_members?.length > 0) {
                            $scope.preferenceFormData.preferred_team_member_ids = $scope.preferenceFormData.preferred_team_members.map((preferred_team_member) => { 
                                return preferred_team_member.id 
                            });   
                        }
                        commonFactory.put('/api/attorneys/preference/' + state_id, $scope.preferenceFormData)
                        .success(function (response) {
                            $rootScope.showLoader(false);
                            Notification.success("Firm created successfully.");
                        
                            if(angular.equals({}, $uibModalInstance)) {
                                if($rootScope.havePermission('attorney_firm','manage_ebt')){
                                    $state.go("firms.manage.ebt", {id: state_id});
                                }
                            }else{
                                $scope.activeTab='tab-4';
                                $scope.attorneyFirmId = state_id;
                                $scope.openEBTTab = true;
                            }
                        })
                    }else{
                        Notification.error(response.result.message);
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false); 
                });
        };

        $scope.change_rates = function() {
           // If the rate is greater then 4.5, need to select drop down value as a OTHER
                $scope.change_rate_type(false);
            
        };

        $scope.change_rate_type =  function(is_manually) {

            if(is_manually){

                var params = {};
                //params.location_id = $scope.formData.location;
                params.rate_id = "R";
                params.id = $scope.formData.regular_other
                
                $rootScope.showLoader(true);

                firmFactory.rates('/api/rates/getRate', params)
                    .success(function(response) {
                        if (response && response.result && response.result.rate_detail) {
                            $scope.formData.appear_rate = response.result.rate_detail.appear_rate;
                            $scope.formData.copy_rate = response.result.rate_detail.copy_rate;
                            $scope.formData.late_appear = response.result.rate_detail.late_appear;
                            $scope.formData.rate = response.result.rate_detail.rate;
                            $scope.formData.no_min_taken_charge = response.result.rate_detail.no_min_taken_charge;
                            $scope.formData.word_indexing_flat_rate = response.result.rate_detail.word_indexing_flat_rate;
                            $scope.formData.min_invoice_charge = response.result.rate_detail.min_invoice_charge;
                            $scope.formData.postage = response.result.rate_detail.postage;
                            $scope.formData.web_amount = response.result.rate_detail.web_amount;
                            $scope.formData.Room = response.result.rate_detail.Room;
                            $scope.formData.SOR = response.result.rate_detail.min_invoice_charge;
                            $scope.formData.SORr = response.result.rate_detail.SORr;
                            $scope.formData.mini_script_flat_rate = response.result.rate_detail.mini_script_flat_rate;
                            $scope.formData.cd_charge = response.result.rate_detail.cd_charge;
                            $scope.formData.e_transcript = response.result.rate_detail.e_transcript;
                            $scope.formData.word_indexing_page_rate = response.result.rate_detail.word_indexing_page_rate;
                            $scope.formData.mini_script_per_page_rate = response.result.rate_detail.mini_script_per_page_rate;
                            $scope.formData.admin_fee = response.result.rate_detail.admin_fee;
                            $scope.formData.web_charge_per_witness = response.result.rate_detail.web_charge_per_witness;
                            $scope.formData.video_conference_handling_and_connection_fee = response.result.rate_detail.video_conference_handling_and_connection_fee;
                            $scope.formData.reminder_charge = response.result.rate_detail.reminder_charge;
                            $scope.formData.legalview_charge = response.result.rate_detail.legalview_charge;
                            $scope.formData.deposition_insights_charge1 = response.result.rate_detail.deposition_insights_charge1;
                            $scope.formData.deposition_insights_charge2 = response.result.rate_detail.deposition_insights_charge2;
                            $scope.formData.deposition_insights_charge3 = response.result.rate_detail.deposition_insights_charge3;
                            $scope.formData.digitization_charge = response.result.rate_detail.digitization_charge;
                            $scope.formData.exhibit_charge = response.result.rate_detail.exhibit_charge;

                            $scope.formData.appearance_hourly = response.result.rate_detail.appearance_hourly;
                            $scope.formData.appearance_hourly_remote = response.result.rate_detail.appearance_hourly_remote;
                            $scope.formData.appearance_hourly_nonsteno = response.result.rate_detail.appearance_hourly_nonsteno;

                            $scope.formData.appear_rate_remote = response.result.rate_detail.appear_rate_remote;
                            $scope.formData.appear_rate_nonsteno = response.result.rate_detail.appear_rate_nonsteno;
                            $scope.formData.rate_remote = response.result.rate_detail.rate_remote;
                            $scope.formData.rate_nonsteno = response.result.rate_detail.rate_nonsteno;
                            $scope.formData.copy_rate_remote = response.result.rate_detail.copy_rate_remote;
                            $scope.formData.copy_rate_nonsteno = response.result.rate_detail.copy_rate_nonsteno;
                            $scope.formData.word_indexing_page_rate_remote = response.result.rate_detail.word_indexing_page_rate_remote;
                            $scope.formData.word_indexing_page_rate_nonsteno = response.result.rate_detail.word_indexing_page_rate_nonsteno;
                            $scope.formData.non_appearance = response.result.rate_detail.non_appearance;

                            $scope.formData.non_appearance_nonsteno  = response.result.rate_detail.non_appearance_nonsteno 
                            $scope.formData.cancellation = response.result.rate_detail.cancellation
                            $scope.formData.cancellation_nonsteno = response.result.rate_detail.cancellation_nonsteno
                            $scope.formData.video_cancellation = response.result.rate_detail.video_cancellation
                            $scope.formData.medical_technical_expert = response.result.rate_detail.medical_technical_expert
                            $scope.formData.medical_technical_expert_nonsteno = response.result.rate_detail.medical_technical_expert_nonsteno
                            $scope.formData.rough_ascii_nonsteno = response.result.rate_detail.rough_ascii_nonsteno

                            $scope.formData.cna = response.result.rate_detail.cna;
                            $scope.formData.no_write = response.result.rate_detail.no_write;
                            $scope.formData.rough_ascii = response.result.rate_detail.rough_ascii;

                            $scope.formData.realtime = response.result.rate_detail.realtime;
                            $scope.formData.exhibits_per_page = response.result.rate_detail.exhibits_per_page;
                            $scope.formData.expedite_percentage_per_day = response.result.rate_detail.expedite_percentage_per_day;
                            $scope.formData.transcript_repository_storage = response.result.rate_detail.transcript_repository_storage;
                            $scope.formData.videographer_fee = response.result.rate_detail.videographer_fee;
                            $scope.formData.video_archiving = response.result.rate_detail.video_archiving;
                          

                            $scope.formData.med_rate = 1;
                            $scope.formData.exp_rate = 1.50;
                            $scope.formData.daily_rate = 3;
                            $scope.formData.immed_rate = 6;
                            $scope.formData.Charge2 = 25;

                            $rootScope.showLoader(false);
                        } else {
                            $scope.formData.appear_rate = '';
                            $scope.formData.copy_rate = '';
                            $scope.formData.late_appear = '';
                            $scope.formData.rate = '';
                            $scope.formData.no_min_taken_charge = '';
                            $scope.formData.word_indexing_flat_rate = '';
                            $scope.formData.min_invoice_charge = '';
                            $scope.formData.postage = '';
                            $scope.formData.web_amount = '';
                            $scope.formData.mini_script_flat_rate = '';
                            $scope.formData.cd_charge = '';
                            $scope.formData.e_transcript = '';
                            $scope.formData.word_indexing_page_rate = '';
                            $scope.formData.Room = '';
                            $scope.formData.SOR = '';
                            $scope.formData.SORr = '';
                            $scope.formData.mini_script_per_page_rate = '';
                            $scope.formData.admin_fee = '';
                            $scope.formData.web_charge_per_witness = '';
                            $scope.formData.video_conference_handling_and_connection_fee = '';
                            $scope.formData.reminder_charge = '';
                            $scope.formData.legalview_charge = '';
                            $scope.formData.deposition_insights_charge1 = '';
                            $scope.formData.deposition_insights_charge2 = '';
                            $scope.formData.deposition_insights_charge3 = '';
                            $scope.formData.digitization_charge = '';
                            $scope.formData.exhibit_charge = '';

                            $scope.formData.appearance_hourly  = '';
                            $scope.formData.appearance_hourly_remote  = '';
                            $scope.formData.appearance_hourly_nonsteno  = '';

                            $scope.formData.appear_rate_remote  = '';
                            $scope.formData.appear_rate_nonsteno  = '';
                            $scope.formData.rate_remote  = '';
                            $scope.formData.rate_nonsteno  = '';
                            $scope.formData.copy_rate_remote  = '';
                            $scope.formData.copy_rate_nonsteno  = '';
                            $scope.formData.word_indexing_page_rate_remote  = '';
                            $scope.formData.word_indexing_page_rate_nonsteno  = '';
                            $scope.formData.non_appearance  = '';

                            $scope.formData.non_appearance_nonsteno  = ''
                            $scope.formData.cancellation = ''
                            $scope.formData.cancellation_nonsteno = ''
                            $scope.formData.video_cancellation = ''
                            $scope.formData.medical_technical_expert = ''
                            $scope.formData.medical_technical_expert_nonsteno = ''
                            $scope.formData.rough_ascii_nonsteno = ''
                            $scope.formData.cna  = '';
                            $scope.formData.no_write  = '';
                            $scope.formData.rough_ascii  = '';

                            $scope.formData.realtime  = '';
                            $scope.formData.exhibits_per_page  = '';
                            $scope.formData.expedite_percentage_per_day  = '';
                            $scope.formData.transcript_repository_storage  = '';
                            $scope.formData.videographer_fee  = '';
                            $scope.formData.video_archiving  = '';

                            $scope.formData.med_rate = '';
                            $scope.formData.exp_rate = '';
                            $scope.formData.daily_rate = '';
                            $scope.formData.immed_rate = '';

                            $rootScope.showLoader(false);
                        }
                    })
                    .error(function(err) { 
                        $rootScope.showLoader(false);
                    });

            }

        };

        //Getting all resources by type.
        commonFactory.post('/api/resource-list-by-type',{rep_typ:'S'})
        .success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.salesPersonsList = response.result.resources;
            }
        });
  
        $(window).bind('keydown', function(event) {

            if (event.ctrlKey || event.metaKey) {
                switch (String.fromCharCode(event.which).toLowerCase()) {

                    case 's':

                        event.preventDefault();

                        if($state.current.name == "firms.add.step_one_add" && !$scope.signupForm.$invalid ){
                            $scope.goto_rate();
                        }
                        if($state.current.name == "firms.add.step_two_add" && !$scope.signupForm.$invalid ){
                            $scope.goto_preference();
                        }
                        if($state.current.name == "firms.add.preference_add" && !$scope.signupForm.$invalid ){
                            $scope.goto_other();
                        }
                        if($state.current.name == "firms.add.step_three_add" && !$scope.signupForm.$invalid ){
                            $scope.save();
                        }
                        // $scope.invoiceGenerateForm.$setSubmitted();
                        $scope.$apply();
                        // $scope.saveJobInvoice($scope.invoiceGenerateForm.$valid,false,false,false);
                        break;
                    case 'n':
                        event.preventDefault();

                        if($state.current.name == "firms.add.step_one_add" && !$scope.signupForm.$invalid){
                            $scope.goto_rate();
                        }
                        if($state.current.name == "firms.add.step_two_add" && !$scope.signupForm.$invalid){
                            $scope.goto_preference();
                        }
                        if($state.current.name == "firms.add.preference_add" && !$scope.signupForm.$invalid ){
                            $scope.goto_other();
                        }
                        if($state.current.name == "firms.add.step_three_add" && !$scope.signupForm.$invalid){
                            $scope.save();
                        }
                        // $scope.invoiceGenerateForm.$setSubmitted();
                        $scope.$apply();
                        // $scope.saveJobInvoice($scope.invoiceGenerateForm.$valid,false,true,false);
                        break;
                }
            }
        });

        $scope.closePopupWindow = function(){
            swal({
                    title: "Are you sure ?",
                    text: "Are you sure want to close this window?",
                    type: "success",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        //$close(JOB_NO);
                        $uibModalInstance.close($scope.firmData);
                    }
                });
        };

        //EBT [It will use full only we open attorney in modal view]
        $scope.ebt = {};
        $scope.ebtlist = function() {
            if ($scope.attorneyFirmId) {
                var data = {};
                data.id = $scope.attorneyFirmId;
                $rootScope.showLoader(true);
                firmFactory.getclerk('/api/getebtclerk/', data.id)
                    .success(function(response) { 
                        $rootScope.showLoader(false);
                        if (response && response.result.ebt) {
                            $scope.ebt = response.result.ebt;
                            for (var i = 0; i < response.result.ebt.length; i++) {
                                $scope.ebt[i].role = response.result.ebt[i].role ? response.result.ebt[i].role.name : "";
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

        $scope.ebtModal = function() {
            var modalInstance = $uibModal.open({
                templateUrl: 'modules/firms/ebt_modal.html',
                controller: 'ebtController',
                resolve: {
                    ebtdata: function ebtFactory() {
                        return $scope.ebt;
                    }
                }
            });
        }

        $scope.editEbt = function(data, index) {
            var modalInstance = $uibModal.open({
                templateUrl: 'modules/firms/ebt_modal.html',
                controller: 'editebtController',
                resolve: {
                    ebt_value: function ebtFactory() {
                        return firmFactory.get('/api/editebtclerk/' + data.id);
                    },
                    index: function ebtIndex() {
                        return index;
                    },
                    ebt_data: function ebtData() {
                        return $scope.ebt;
                    }
                }
            });
        }

        $scope.deleteEbt = function(data) {
            swal({
                    title: "Are you sure?",
                    text: "you want to delete this EBT Clerk!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No, cancel EBT Clerk!",
                    closeOnConfirm: false,
                    closeOnCancel: false
                },
                function(isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        firmFactory.post('/api/deleteebtclerk', data)
                            .success(function(response) {
                                if (response.status == 200) {
                                    $rootScope.showLoader(false);
                                    var index = -1;
                                    var comArr = eval($scope.ebt);
                                    for (var i = 0; i < comArr.length; i++) {
                                        if (comArr[i].id === data.id) {
                                            index = i;
                                            break;
                                        }
                                    }
                                    if (index === -1) {
                                        $rootScope.showLoader(false); 
                                        swal('Cancelled', 'Something gone wrong', 'error');
                                    }
                                    $scope.ebt.splice(index, 1);
                                }
                                swal('Deleted!', 'EBT Clerk deleted successfully', 'success');
                            })
                            .error(function(err) {
                                $rootScope.showLoader(false);
                                swal('Cancelled', 'Something gone wrong', 'error');
                            });
                    } else {
                        $rootScope.showLoader(false);
                        swal("Cancelled", "Your EBT Clerk is safe :)", "error");
                    }
                });
        }

    }
]);
