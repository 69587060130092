angular.module('deitz')
    .controller('workInProgressByReporterController', [
        '$scope',
        '$state',
        '$rootScope',
        '$http',
        'apiUrl',
        '$stateParams',
        'commonFactory',
        'Notification',
        '$sce',
        '$window',
        function($scope,
                 $state,
                 $rootScope,
                 $http,
                 apiUrl,
                 $stateParams,
                 commonFactory,
                 Notification,
                 $sce,
                 $window
        ){

            $scope.report = {};
            $scope.selectedReporters = [];
            $scope.selectedAttorneys = [];
            $scope.selectedSourcesId = [];
            $scope.siteSetupData = [];
            $scope.reportType = 'reporter';
            $scope.btnText = 'Search All';
            $scope.isRec = false;
            $scope.noOfWorkInProgress = 0;
            $scope.businessUnitJobCount = {};
            $scope.resource_type = {};
            $scope.formData = {
                selectedGroups:[],
                selectedAttorneys:[]
            };
            
            //Getting all sources from source table.
            $http.get(apiUrl + '/api/get_source').then(function(response) { 
                $scope.sourcesList=response.data.data;       
            }); 

            $scope.trustAsHtml = function(html) {
                return $sce.trustAsHtml(html);
            };

            // gGROUPNAME START
            $rootScope.showLoader(true);
            commonFactory.get('/api/get-firm-groups')
            .success(function(response) {
                $rootScope.showLoader(false);
                $scope.firmGroups = response.result;
            })
            .error(function(err) {
                $rootScope.showLoader(false);
            });
            
            $scope.groupChange = function(){
                if($scope.formData.selectedGroups.length == 1 && $scope.formData.selectedGroups[0].group_name.toLowerCase().indexOf('nyct') != -1){
                    $scope.isOnlyNYCTAGroupSelected = true;
                }else{
                    $scope.isOnlyNYCTAGroupSelected = false;
                }
    
                // $scope.tagRemoved();
                var groups = [];    
                angular.forEach($scope.formData.selectedGroups, function(value, key) {
                   groups[key] = value.group_name;
                });
                
                // get firms of selected groups
                $rootScope.showLoader(true);
                commonFactory.post('/api/get-firms-of-selected-groups',{groups:groups})
                .success(function(response) {
                    $rootScope.showLoader(false);
                    $scope.formData.selectedAttorneys = response.result;
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
    
            };

            $scope.attorneysList = function(val) {
                $scope.searchingAtt = true;
                return $http.get(apiUrl + '/api/attorneySearchByName', {
                    params: {
                        name: val
                    }
                }).then(function(response) {
                    $scope.searchingAtt = false;
                    return response.data.result.map(function(item) {
                        var department = item.department_name ? ' || '+ item.department_name : '';
                        item.NAME = item.NAME + department;
                        return item;
                    });
                });
            };
            // end of group name
            $scope.additional_resource_types={};
            $http.get(apiUrl + '/api/additionalinfo_list').then(function(response) { 
                angular.forEach( response.data.result.data, function (value, key) {
                    $scope.additional_resource_types[value.id] =value.name;
                    $scope.report.resource_type = value.name;
                });
              // add seperate type for typist
              //$scope.additional_resource_types['T'] ='Typist'; 
            });
            $scope.startDateOpened = false;
            $scope.endDateOpened = false;
            $scope.jobDueDateOpened = false;
            $scope.openDatePicker = function (field) {
                if (field === 'startDate') {
                    $scope.startDateOpened = true;
                    $scope.jobDueDateOpened = false;
                    $scope.endDateOpened = false; 
                } else if (field === 'endDate') {
                    $scope.startDateOpened = false;
                    $scope.jobDueDateOpened = false; 
                    $scope.endDateOpened = true;
                } else if(field === 'jobDueDate'){
                    $scope.endDateOpened = false;
                    $scope.startDateOpened = false;
                    $scope.jobDueDateOpened = !$scope.jobDueDateOpened;
                }
            };
            //get business units
            $scope.selectedBusinessUnitIDs = [];
            var selectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
            if(selectedBusinessUnitIDs && selectedBusinessUnitIDs.length){
                $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
            }else{
                $rootScope.auth_user.roles_businessunits.forEach(function (e) {
                $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
                });
            }

            $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
                $scope.BusinessUnitsList = response.data.result.data;  
                $scope.BusinessUnitsList.unshift({name:'All'});

            });

            if($stateParams.repId){
                $rootScope.showLoader(true);
                commonFactory.get('/api/reptype/'+$stateParams.repId+'/edit')
                    .success(function(response) {
                        $scope.selectedReporters.push(response.result.reptypeInfo);
                        $scope.searchData();
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }else if($stateParams.attId){
                $rootScope.showLoader(true);
                $scope.reportType = 'attorney'; 
                commonFactory.get('/api/attorneys/'+$stateParams.attId)
                    .success(function(response) { 
                        $scope.selectedAttorneys.push(response.result.data);
                        $scope.searchData();
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
            $scope.title = "Work in progress by resource / attorney";

            $scope.onTagAdded = function(){
                $scope.btnText = 'Search'
            }
            $scope.onTagRemoved = function(selectedItems){ 
                if(selectedItems.length <= 0){
                    $scope.btnText = 'Search All';
                }
            }
            $scope.setReportType = function(reportType){
                $scope.isRec = false;
                $scope.btnText = 'Search All';
                $scope.selectedReporters = [];
                $scope.selectedAttorneys = [];
                $scope.reporterJobs = {};
                $scope.reporterJobsId = {};
                $scope.siteSetupData ={};
                $scope.reportType = reportType;
            }
            //Getting Reporter list
            $scope.reporterList = function(val) {
                return $http.get(apiUrl + '/api/listRepByType', {
                    params: {
                        search: val,
                        rep_typ: 'R'
                    }
                }).then(function(response) {
                    return response.data.result.map(function(item) {
                        return item;
                    });
                });
            };

            //Getting Attorneys list
            // $scope.attorneysList = function(val) {
            //     $rootScope.showLoader(true);
            //     return $http.get(apiUrl + '/api/attorneySearchByName', {
            //         params: {
            //             name: val
            //         }
            //     }).then(function(response) {
            //         $rootScope.showLoader(false);
            //         return response.data.result.map(function(item) {
            //             return item;
            //         });
            //     });
            // }; 

           
            /* Comparing due date with current time in order to display in red color */
            $scope.calculateDueDate = function(dteTaken){

                // $scope.siteSetupData.daysdue
                var dueDte = moment(dteTaken, 'MM/DD/YYYY').add($scope.siteSetupData.daysdue, 'days');

                var now = moment();
                dueDteObj = moment(dueDte, 'MM/DD/YYYY');

                if(now > dueDteObj){
                    return '<span class="error-message red">'+ moment(dueDte).format('MM/DD/YYYY') +'</span>'
                }
                return moment(dueDte).format('MM/DD/YYYY');
            };
            
            $scope.format = 'MM/dd/yyyy'; 
            $scope.jobDueDate = null; 
            $scope.jobDate = null;
            $scope.endDateError = false;

            $scope.dateOptionsStart = {
                maxDate: new Date(), 
                showWeeks: true
            };
            
            $scope.dateOptionsEnd = {
                maxDate: new Date(), 
                showWeeks: true
            };
            
            $scope.$watchGroup(['startDate', 'endDate'], function (newValues, oldValues) {
                var startDate = new Date(newValues[0]);
                var endDate = new Date(newValues[1]);
                if (startDate > endDate) {
                    $scope.endDateError = true;
                } else {
                    $scope.endDateError = false;
                }
            });
            $scope.dateOptions = {
                maxDate: new Date(2025, 12, 31),
                minDate: new Date(2000, 1, 1),
                startingDay: 1,
                showWeeks: false
            };

            $scope.jobDateOpened = false;

            $scope.searchData = function(){
                $rootScope.showLoader(true); 
                $scope.report = {};
                if($scope.reportType == 'reporter'){ 
                    
                    $scope.report.reporterIds = $scope.selectedReporters.map(function(a){
                        return  a.id;
                    });
                }else if($scope.reportType == 'attorney'){

                    $scope.report.attorneyIds = $scope.selectedAttorneys.map(function(a){
                        return  a.id;
                    });
                }else if($scope.reportType == 'groupbyname'){
                    $scope.report.attorneyIds = $scope.formData.selectedAttorneys.map(function(a){
                        return a.id;
                    });
                }

                $scope.report.selectedSourcesId = $scope.selectedSourcesId;
                $scope.report.resourceType = $scope.resource_type;
                $scope.report.startDate = $scope.startDate ? moment($scope.startDate).format('MM/DD/YYYY') : '';
                $scope.report.endDate = $scope.endDate ? moment($scope.endDate).format('MM/DD/YYYY') : '';
                $scope.report.jobDueDate = $scope.jobDueDate ? moment($scope.jobDueDate).format('MM/DD/YYYY') : '';
                $scope.report.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);
                localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean)));
                $scope.jobsduration = [];
                commonFactory.post('/api/reporter-work-in-progress-report', $scope.report)
                    .success(function(response) {
                        $scope.reporterJobs = [];
                        $scope.reporterJobsId = [];
                        $scope.noOfWorkInProgress = 0;

                        $rootScope.showLoader(false);
                        if(response.error){
                            Notification.error(response.result.message || 'Something went wrong!');
                        } else{
                            $scope.reporterJobs = response.result.jobs;
                            $scope.siteSetupData = response.result.siteSetupData;
                            angular.forEach($scope.reporterJobs, function(value, key) {
                                $scope.noOfWorkInProgress = $scope.noOfWorkInProgress + value.length;
                            });

                            $scope.businessUnitJobCount = {};
                            angular.forEach($scope.reporterJobs, function(jobs, key) {
                                // Update the count for each business unit
                                angular.forEach(jobs, function(job) {
                                    if (job.business_unit_id) {
                                        if ($scope.businessUnitJobCount[job.business_unit_id]) {
                                            $scope.businessUnitJobCount[job.business_unit_id]++;
                                        } else {
                                            $scope.businessUnitJobCount[job.business_unit_id] = 1;
                                        }
                                    }
                                    // Collecting all job IDs
                                    if (job.resource_id && $scope.reporterJobsId.indexOf(job.resource_id) === -1) { 
                                        $scope.reporterJobsId.push(job.resource_id);
                                    }
                                    if (job.JOB_LENGTH !== undefined && job.JOB_LENGTH !== null && job.JOB_LENGTH.trim() !== '') {
                                        var parts = job.JOB_LENGTH.split(':');
                                        var hours = parseInt(parts[0], 10);
                                        var minutes = 0;
                                        if (parts.length > 1) {
                                            minutes = parseInt(parts[1].replace('m', ''), 10) / 60;
                                        }
                                        job.JOB_LENGTH = (hours + minutes).toFixed(1);
                                    }else{
                                    job.JOB_LENGTH = 0;
                                    }
                                });
                                $scope.reporterJobs[key] = jobs;
                              });

                            if($scope.reporterJobs.length == 0){
                                Notification.error('No records found!');
                            }else{
                                $scope.isRec = true;
                            }
                        }
                    })
                    .error(function(err)
                    {
                        $scope.reporterJobs = [];
                        $scope.reporterJobsId = [];
                        Notification.error('Something went wrong!');
                        $rootScope.showLoader(false);
                    });
            };
            
            $scope.jobduration = {};
            $scope.job_durationError = {};
            $scope.length = 5;
            $scope.updateJobDuration = function(jobId,JobDuraion, resourceId){
                $scope.jobduration.jobId = jobId;
                $scope.jobduration.jobDuration = JobDuraion;
               
                if (/^\d{0,2}(\.\d{0,2})?$/i.test(JobDuraion)){
                    if(JobDuraion >24){
                        $scope.job_durationError[jobId] = true;
                        $scope.errorMsg = "Job duration must be less than 24";
                        
                    }else if(JobDuraion <=24){
                        $scope.job_durationError[jobId] = false;
                        $http.post(apiUrl + '/api/jobDurationUpdate',$scope.jobduration).success(function(response){
                            $scope.calculateEstPages(jobId,JobDuraion);
                        }).error(function(error){
        
                        });
                    }
                }else{
                    
                    $scope.job_durationError[jobId] = true;
                    $scope.errorMsg = "Please enter a valid number.";
                    return null;
                }
                
            }
            $scope.onlyNumbers = /^(0?[0-2]|1[0-4]|2[0-3])(\.\d{1,2})?$|^24(\.00)?$/;

            
            $scope.validateInput = function(event) {
                const key = event.key;
                const currentValue = event.target.value;
                const inputElement = event.target;
                const caretPosition = inputElement.selectionStart;
                
                const parts = currentValue.split('.');
                const beforeDot = parts[0];
               
                const afterDot = parts[1] || '';
              
                const secondPosition = 1; // Position of the second character
                const thirdPosition = 2;
                
                if (
                    (parseFloat(currentValue) >= 24 && !currentValue.includes('.')) ||
                    (parseFloat(currentValue) >= 24.00 && currentValue.includes('.') ) || (currentValue.length == 2 && key != '.' && !currentValue.includes('.')) || (afterDot.length == 2)
                ) {
                    event.preventDefault();
                    return;
                }
                
                
                if (beforeDot.length > 2 || afterDot.length > 2) {
                    
                    if (key !== '.') {
                    event.preventDefault();
                    return;
                    }
                }
                
                
            };
            
            
            $scope.calculateEstPages = function(jobId,jobDuration) {
                
                if(jobDuration >24){
                    return 0;
                }
                return Math.round(jobDuration * 40);
                
            };

            $scope.printDiv = function (divName) { 

                var printContents = $("#" + divName); 
                printContents = printContents.html();
                printContents = printContents.replace(/<span[^>]*btnemail[^>]*>(.*?)<\/span>/g, '$1');
                var popupWin = window.open('', '_blank');
                popupWin.document.open();
                popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="css/common.css"><link rel="stylesheet" type="text/css" href="font-awesome/css/font-awesome.css"></head><body onload="window.print()">' + printContents + '</body></html>');
                popupWin.document.close();
            }
            $scope.getBusinessUnitDetails = function(jobs) {
                var businessUnitJobCount = {};
                angular.forEach(jobs, function(job) {
                    if (job.business_unit_id) {
                        if (!businessUnitJobCount[job.business_unit_id]) {
                            businessUnitJobCount[job.business_unit_id] = 0;
                        }
                        businessUnitJobCount[job.business_unit_id]++;
                    }
                });
                return businessUnitJobCount;
            };
            $scope.sendemail = function(divName, resource_id = null, message = null, BUId = null) {

                var data = [];
                if (Array.isArray(resource_id) || resource_id.length > 0) {
                    resource_id.forEach(function(resourceid) {
                        if (!isNaN(resourceid) && typeof resourceid === 'number') {
                            res_divName = divName + '_' + resourceid;
                        }
                        var contents = $("#" + res_divName);
                        if (contents.length) {
                            var htmlContent = contents.html();
                            htmlContent = htmlContent.replace(/<span[^>]*btnemail[^>]*>(.*?)<\/span>/g, '$1');
                            
                            data.push({ 
                                mailContent: htmlContent, 
                                resource_id: resourceid 
                            });
                        }
                    });
                }else{
                    if (!isNaN(resource_id) && typeof resource_id === 'number') {
                        res_divName = divName + '_' + resource_id;
                    }
                    var contents = $("#" + res_divName); 
                    contents = contents.html();
                    contents = contents.replace(/<span[^>]*btnemail[^>]*>(.*?)<\/span>/g, '$1');
                    data.push({ 
                        mailContent: contents, 
                        resource_id: resource_id 
                    });
                }
                
                if (message) {
                    message += "\n";
                    angular.forEach(BUId, function(count, businessUnitId) {
                        message += $scope.BusinessUnitsList.find(bu => bu.id == businessUnitId)?.name  + " - " + count + " job(s)\n";
                    });
                } else{
                    message = "\n";
                    angular.forEach($scope.businessUnitJobCount, function(count, businessUnitId) {
                        message += $scope.BusinessUnitsList.find(bu => bu.id == businessUnitId)?.name  + " - " + count + " job(s)\n";
                    });
                }
                swal({
                    title: "Are you sure you want to send the email ?",
                    text: "For "+ message ,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes, Do it",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        if (data.length > 0) {
                            $rootScope.showLoader(true);            
                            commonFactory.post('/api/send-work-in-progress-report', data )
                                .success(function(response) {
                                    $rootScope.showLoader(false);
                                    if (response.status === 200) {
                                        Notification.success(response.result.message);
                                    } else {
                                        Notification.error(response.result.message || "Failed to send emails.");
                                    }
                                })
                                .error(function(err) {
                                    $rootScope.showLoader(false);
                                    Notification.error("Something went wrong while sending emails.");
                                });
                        } else {
                            Notification.error("No valid content to send.");
                        }  
                    }
                });             
            }

            $scope.exportData = function() {
                $rootScope.showLoader(true); 
                $scope.report = {};
                if($scope.reportType == 'reporter'){ 
                    
                    $scope.report.reporterIds = $scope.selectedReporters.map(function(a){
                        return  a.id;
                    });
                }else if($scope.reportType == 'attorney'){

                    $scope.report.attorneyIds = $scope.selectedAttorneys.map(function(a){
                        return  a.id;
                    });
                }else if($scope.reportType == 'groupbyname'){
                    $scope.report.attorneyIds = $scope.formData.selectedAttorneys.map(function(a){
                        return a.id;
                    });
                }

                $scope.report.reportType = $scope.reportType;
                $scope.report.selectedSourcesId = $scope.selectedSourcesId;
                $scope.report.resourceType = $scope.resource_type;
                $scope.report.startDate = $scope.startDate ? moment($scope.startDate).format('MM/DD/YYYY') : '';
                $scope.report.endDate = $scope.endDate ? moment($scope.endDate).format('MM/DD/YYYY') : '';
                $scope.report.jobDueDate = $scope.jobDueDate ? moment($scope.jobDueDate).format('MM/DD/YYYY') : '';
                $scope.report.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);
                localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean)));
                $scope.jobsduration = [];
                commonFactory.post('/api/download-excel-work-in-progress-report', $scope.report)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response.status == 200) {
                            if (response.result.fileName) {
                                $window.open(apiUrl +'/api/download-work-in-progress-report?fileName='+response.result.fileName);
                            }
                        }
                    });
            }

        }]);
