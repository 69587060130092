angular.module('deitz')
    .controller('paidReportersButNotInvoicedController', [
        '$scope',
        '$rootScope',
        '$http',
        'apiUrl',
        'DTOptionsBuilder',
        'DTColumnBuilder',
        'payrollFactory',
        '$compile',
        '$uibModal',
        'Notification',
        '$filter',
        '$timeout',
        '$q',
        '$stateParams',
        function ($scope,
                  $rootScope,
                  $http,
                  apiUrl,
                  DTOptionsBuilder,
                  DTColumnBuilder,
                  payrollFactory,
                  $compile,
                  $uibModal,
                  Notification,
                  $filter,
                  $timeout,
                  $q,
                  $stateParams) {

            $scope.title = "Invoiced But not paid resource";
            $scope.dtInstance = {};

            $scope.report = {};
            $scope.report.start_date = moment().startOf('month');
            // $scope.maxDate=moment();
            $scope.report.end_date =moment();
            
            $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
                $scope.BusinessUnitsList = response.data.result.data;  
                $scope.BusinessUnitsList.unshift({name:'All'});

                let all_pay_dates = [];
                angular.forEach( response.data.result.data, function (value, key) {
                    if(value.pay_date && value.id == 7){
                        all_pay_dates.push(new Date(value.pay_date));
                    }
                });
                let max = all_pay_dates.reduce(function (a, b) { return a > b ? a : b; });
                $scope.report.end_date = max;
            });

            $scope.selectedBusinessUnitIDs = [];
            var selectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
            if(selectedBusinessUnitIDs && selectedBusinessUnitIDs.length){
                $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
            }else{
                $rootScope.auth_user.roles_businessunits.forEach(function (e) {
                    $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
                });
            }
            
            $scope.additional_resource_types={};
            $http.get(apiUrl + '/api/additionalinfo_list').then(function(response) { 
                angular.forEach( response.data.result.data, function (value, key) {
                    $scope.additional_resource_types[value.id] =value.name;
                });
              // add seperate type for typist
              //$scope.additional_resource_types['T'] ='Typist'; 
            });

            $scope.reloadData = function () {
                $scope.dtInstance.rerender();
            };

            $scope.searchData = function(){
                $scope.dtInstance.DataTable.state.clear();
                $scope.reloadData();
            }

            $scope.headerCompiled = false;

            $scope.createdRow = function (row, data, dataIndex) {
                $compile(angular.element(row).contents())($scope);
            };

            $scope.dtOptions = DTOptionsBuilder.newOptions()
                .withDataProp('data')
                .withOption('ajax', function (data, callback, settings) {
                    // map your server's response to the DataTables format and pass it to
                    data.start_date = moment($scope.report.start_date).format('MM/DD/YYYY');
                    data.end_date = moment($scope.report.end_date).format('MM/DD/YYYY');
                    data.resource_type= $scope.report.resource_type;
                    data.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);;
                    localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean)));
                    payrollFactory.showDataTable('/api/paid-reporter-but-not-invoiced', data).success(function (res) {
                        if (res.error) {
                           // $scope.reloadData();
                        }
                        else {
                            callback(res);
                        }
                    }).error(function (err) {
                        if (err.error !== "token_not_provided") {
                            //$scope.reloadData();
                        }
                    });
                })
                .withOption('processing', true)
                .withLanguage({
                    // "sEmptyTable": "NO INVOICE AVAILABLE IN TABLE",
                    // "sInfo": "SHOWING _START_ TO _END_ OF _TOTAL_ INVOICES",
                    // "sInfoEmpty": "SHOWING 0 TO 0 OF 0 INVOICES",
                    // "sInfoFiltered": "(FILTERED FROM _MAX_ TOTAL INVOICES)",
                    // "sInfoPostFix": "",
                    // "sInfoThousands": ",",
                    // "sLengthMenu": "SHOW _MENU_ INVOICES",
                    // "sLoadingRecords": "<img src='img/loading_bar.gif'/>",
                    "sProcessing": "<img src='img/loading_bar.gif'/>",
                    // "sSearch": "",
                    // "sZeroRecords": "NO MATCHING INVOICE FOUND",
                    // "oPaginate": {
                    //     "sFirst": "FIRST",
                    //     "sLast": "LAST",
                    //     "sNext": "NEXT",
                    //     "sPrevious": "PREVIOUS"
                    // },
                    // "oAria": {
                    //     "sSortAscending": ": ACTIVATE TO SORT COLUMN ASCENDING",
                    //     "sSortDescending": ":   ACTIVATE TO SORT COLUMN DESCENDING"
                    // }
                })
                .withOption('serverSide', true)
                .withOption('stateSave', true)
                .withPaginationType('simple_numbers')
                .withOption('searchDelay', 500)
                // .withOption('searching', false)
                .withOption('order', [1, 'desc'])
                .withOption('createdRow', $scope.createdRow)
                .withOption('headerCallback', function (header) {
                    // Use this headerCompiled field to only compile header once
                    if (!$scope.headerCompiled) {
                        $compile(angular.element(header).contents())($scope);
                    }
                })
                .withLightColumnFilter({
                    '0': {
                        type: 'text',
                    },
                    '1': {
                        type: 'text',
                    },
                    // '2': {
                    //     type: 'text',
                    // },
                    // '6': {
                    //     type: 'text',
                    // },
                    // '9': {
                    //     type: 'text',
                    // }

                });

            $scope.dtColumns = [];

            $scope.dtColumns.push(

                DTColumnBuilder.newColumn('JOB_NO').withOption('name', 'JOB_tbl.JOB_NO').withOption('width', '15%').withOption('defaultContent', '').withTitle('Job No').renderWith(function(data,type,full,meta){
                    return  '<a class="pull-left" target="_blank" href="javascript:void(0);" ui-sref="diary.editcase.step_one({id : ' + data + ' })">'+data+'</a>';
                }),                
                DTColumnBuilder.newColumn('CAPTION').withOption('name', 'JOB_tbl.CAPTION').withOption('defaultContent', '').withTitle('Caption'),
                // DTColumnBuilder.newColumn('REP_PAYAMT').withOption('name', 'JOB_tbl.REP_PAYAMT').withOption('defaultContent', '$ 0.00').withOption('width', '20%').withTitle('REP_PAYAMT').renderWith(function (data, type, full, meta) {
                //     if (data) {
                //         return $filter('currency')(data);
                //     } else {
                //         return $filter('currency')(0);
                //     }
                // }),
                DTColumnBuilder.newColumn("dte_taken").withOption('name', 'JOB_tbl.dte_taken').withTitle('Job Date').withOption('width', '20%').renderWith(function (data) {
                    return (data && data != '0000-00-00')?moment(data).format('MM/DD/YYYY') : '-';
                }),
                // DTColumnBuilder.newColumn("PAID_DT").withOption('name', 'INVOICE_tbl.PAID_DT').withTitle('INV PAID DT').withOption('width', '8%').renderWith(function (data) {
                //     return data || '-'
                // }),
                DTColumnBuilder.newColumn("REP_NAME").withOption('name', 'REP_NAME').withTitle('Resource').renderWith(function (data) {
                    return data || '-'
                }),
                DTColumnBuilder.newColumn('REP_PAYAMT').withOption('name', 'job_resources.REP_PAYAMT').withOption('defaultContent', '').withOption('width', '8%').withTitle('Rep Payamt').renderWith(function (data, type, full, meta) {
                    if (data) {
                        return $filter('currency')(data);
                    } else {
                        return $filter('currency')(0);
                    }
                }),
                DTColumnBuilder.newColumn("REP_PAYDT").withOption('name', 'job_resources.REP_PAYDT').withTitle('Rep PayDT').withOption('width', '8%').renderWith(function (data) {
                    return (data && data != '0000-00-00')?moment(data).format('MM/DD/YYYY') : '-';
                }),
                DTColumnBuilder.newColumn('bu_name').withOption('defaultContent', '').withTitle('Business Unit').withOption('name', 'original_bu.name').withOption('searchable', true).withOption("width", "10%").renderWith(function(data, type, full, meta){
                    if (data) {
                        data = escapeString(data);
                        var caption = "<div uib-tooltip='"+data+ "'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+data+"' | limitTocustom:30}}</div>";
                        return caption;
                    } else {
                        return '<center>-</center>';
                    }
                }),
                DTColumnBuilder.newColumn('cbu_name').withOption('defaultContent', '').withTitle('Covering Business Unit').withOption('name', 'covering_bu.name').withOption('searchable', true).withOption("width", "10%").renderWith(function(data, type, full, meta){
                    if (data) {
                        data = escapeString(data);
                        var caption = "<div uib-tooltip='"+data+ "'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+data+"' | limitTocustom:30}}</div>";
                        if(full.is_ready_to_release_to_bu=='1'){
                            caption+= '<lable class="label label-success">Released</lable>';
                        }
                        return caption;
                    } else {
                        return '<center>-</center>';
                    }
                }),
                // DTColumnBuilder.newColumn("TYP_NAME").withOption('name', 'TYP_NAME').withTitle('TYPIST').renderWith(function (data) {
                //     return data || '-'
                // }),
                // DTColumnBuilder.newColumn('TYP_PAYAMT').withOption('name', 'JOB_tbl.TYP_PAYAMT').withOption('width', '8%').withTitle('TYP_PAYAMT').renderWith(function (data, type, full, meta) {
                //     if (data) {
                //         return $filter('currency')(data);
                //     } else {
                //         return $filter('currency')(0);
                //     }
                // }),
                // DTColumnBuilder.newColumn("TYP_PAYDT").withOption('name', 'JOB_tbl.TYP_PAYDT').withTitle('TYP_PAYDT').withOption('width', '8%').renderWith(function (data) {
                //     return (data && data != '0000-00-00')?moment(data).format('MM/DD/YYYY') : '-';
                // })


            );



        }
    ]);