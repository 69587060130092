angular.module('deitz').factory('codeFactory', ['$http', 'apiUrl', function($http, apiUrl) {

    var codeFactory = {};

    function convertParams(params) {
        var reqData = {};
        if (params) {
            reqData.skip = params.skip || 0; // This is NOT the page number, but the index of item in the list that you want to use to display the table.
            reqData.limit = params.limit || 10; // Number of entries showed per page.
            reqData.search = params.search ? params.search.value : '';
            reqData.orderColumn = params.order ? params.order[0].column : '';
            reqData.orderDir = params.order ? params.order[0].dir : '';
            if (params.id) {
                reqData.id = params.id ? params.id : '';
            }
        }
        return reqData;
    };

    //Include validation helper
    var vh = validation_helper();

    codeFactory.validations = {
        name: {
            maxlength: 100,
            required: true,
            pattern: /^[a-zA-Z0-9_ .-]*$/
        },
        name2: {
            maxlength: 100,
            required: true,
        },
        slug: {
            maxlength: 5,
            required: true
        },
        company_name: {
            maxlength: 100,
            required: true,
        },
        held_at: {
            maxlength: 200,
            required: true,
        },
        city: {
            maxlength: 100,
            required: true,
        },
        state: {
            maxlength: 100,
            required: true,
        },
        zipcode: {
            required: vh.zipcode.required,
            patterns : vh.zipcode.patterns,
            patternRestrict : vh.zipcode.patternRestrict,
            maxlength: vh.zipcode.maxlength,
            minlength: vh.zipcode.minlength
        },
        phone_number: {
            maxlength: 50,
            required: true,
        },
        address1: {
            maxlength: 150,
            required: true,
        },
        address2: {
            maxlength: 150,
            required: true,
        },
        CompanyName: {
            maxlength: 150,
            required: true,
        },
        MainPhoneNumber: {
            maxlength: 50,
            required: true,
        },
        MainFaxNumber: {
            maxlength: 50,
            required: true,
        },
        City: {
            maxlength: 100,
            required: true,
        },
        State: {
            maxlength: 100,
            required: true,
        },
        ZipCode: {
            maxlength: 50,
            required: true,
        },
        Address1: {
            maxlength: 150,
            required: true,
        },
        Address2: {
            maxlength: 150,
            required: true,
        },
        claimrepresentativeaddress_id: {
            required: true,
        },
        ClaimRepFirstName: {
            maxlength: 150,
            required: true,
        },
        ClaimRepLastName: {
            maxlength: 150,
            required: true,
        },        
        order_number: {
            required: true,            
        },
        charge_code: {
            required: true,
            maxlength: 50,
        },
        description: {
            required: true,
            maxlength: 500,
        },
        amount: {
            required: true,
            maxlength: 10,
            pattern: /^\d+(\.\d{1,2})?$/
        },
        status: {
            required: true
        },
        logo:{
            required:true
        },
        email: {
            patterns: vh.email.patterns,
            maxlength: vh.email.maxlength,
            required: vh.email.required,
        },
        held_at_flag: {
            required : true
        },
        ein: {
            maxlength: 50,
            required : true
        },
        entity: {
            maxlength: 80,
            required : true
        },
        label: {
            maxlength: 20,
            required : true
        },
        dba: {
            maxlength: 80,
            required : true
        },
        grade: {
            maxlength: 80,
            required : true
        },
        type: {
            maxlength: 100,
            required : true
        },
        achieved_date: {
            required : true
        },
        expiration_date: {
            required : true
        },
        certificates_type_id: {
            required : true
        },
        start_date: {
            required : false
        },
        end_date: {
            required : false
        },
        start_time: {
            required : false
        },
        end_time: {
            required : false
        },
        notes: {
            required : false
        },
        email_content :{
            minlength: 50,
            required : true,
        },
        JobNumber: {            
            required: true,
        },
        templateName: {            
            required: true,
        },
        zipFile: {            
            required: true,
        },
        
    };

    codeFactory.messages = {
        name: {
            required: "Name is required.",
            maxlength: "Name not be upto 100 characters.",
            pattern: "Please enter valid name(Only letters and numbers)."
        },       
        name2: {
            required: "Name2 is required.",
            maxlength: "Name2 not be upto 100 characters."
        },
        slug: {
            maxlength: "Slug not be upto 5 characters.",
            required: "Slug is required."
        },
        company_name: {
            required: "Company Name is required.",
            maxlength: "Company Name not be upto 100 characters."
        },
        held_at: {
            maxlength: "Held At not be upto 200 characters.",
            required: "Held at is required.",
        },
        city: {
            maxlength: "City not be upto 100 characters.",
            required: "City is required.",
        },
        state: {
            maxlength: "State not be upto 100 characters.",
            required: "State is required.",
        },
        zipcode: {
            maxlength: "Zipcode not be upto 50 characters.",
            required: "Zipcode is required.",
            patterns : "Zipcode is invalid"
        },
        phone_number: {
            maxlength: "Phone not be upto 50 characters.",
            required: "Phone is required.",
        },
        address1: {
            maxlength: "Address1 not be upto 150 characters.",
            required: "Address1 is required.",
        },
        address2: {
            maxlength: "Address2 not be upto 150 characters.",
            required: "Address2 is required.",
        },
        CompanyName: {
            maxlength: "Company name must be upto 150 characters.",
            required: "Company name is required.",
        },
        MainPhoneNumber: {
            maxlength: "Phone not be upto 50 characters.",
            required: "Phone is required.",
        },
        MainFaxNumber: {
            maxlength: "Fax number not be upto 50 characters.",
            required: "Fax number is required.",
        },
        City: {
            maxlength: "City not be upto 100 characters.",
            required: "City is required.",
        },
        State: {
            maxlength: "State not be upto 100 characters.",
            required: "State is required.",
        },
        ZipCode: {
            maxlength: "Zipcode not be upto 50 characters.",
            required: "Zipcode is required.",
        },
        Address1: {
            maxlength: "Address1 not be upto 150 characters.",
            required: "Address1 is required.",
        },
        Address2: {
            maxlength: "Address2 not be upto 150 characters.",
            required: "Address2 is required.",
        },
        claimrepresentativeaddress_id: {
            required: "Claim Representative id is required.",
        },
        ClaimRepFirstName: {
            maxlength: "Firstname not be upto 150 characters.",
            required: "Firstname is required.",
        },
        ClaimRepLastName: {
            maxlength: "Lastname not be upto 150 characters.",
            required: "Lastname is required.",
        },        
        order_number: {
            required: "Group Charge Order Number is required.",
            maxlength: "Group Charge Order Number not be upto 2 characters.",
        },
        group_charge_id: {
            required: "Group Charge is required.",
            maxlength: "Charge Code not be upto 10 characters.",
        },
        charge_code: {
            required: "Charge Code is required.",
            maxlength: "Charge Code not be upto 50 characters.",
        },
        description: {
            required: "Description is required.",
            maxlength: "Description not be upto 500 characters.",
        },
        amount: {
            required: "Amount is required.",
            maxlength: "Amount not be upto 10 characters.",
            pattern: "Please enter valid Amount."
        },
        status: {
            required: "Status is required."
        },
        logo:{
            required:"Logo is required"
        },
        email: {
            patterns: "Please enter valid email.",
            required: "Email is required.",
            maxlength: "Email Field not be upto 254 characters."
        },
        held_at_flag: {
            required : "Held at flag is required."
        },
        ein: {
            required : "EIN is required."
        },
        entity: {
            required : "Entity is required."
        },
        label: {
            required : "Label is required."
        },
        dba: {
            required : "DBA is required."
        },
        grade: {
            required: "Grade is required.",
            maxlength: "Grade not be upto 80 characters."
        },
        type: {
            required: "Certificate type is required.",
            maxlength: "Certificate type not be upto 100 characters."
        },
        achieved_date: {
            required: "Achieved date is required.",
        },
        expiration_date: {
            required: "Expiration date is required.",
        },
        certificates_type_id: {
            required: "Certificate is required.",
        },
        business_unit_id: {
            required: "Business Unit is required.",
        },
        start_date: {
            required: "Start date is required.",
        },
        end_date: {
            required: "End date is required.",
        },
        start_time: {
            required: "Start time is required.",
        },
        end_time: {
            required: "End time is required.",
        },
        notes: {
            required: "Notes is required.",
        },
        email_content:{
            required: "Description is required.",
            minlength: "Description not be upto 100 characters.",
        },
        JobNumber: {            
            required: "Job Number is required.",
        },
        templateName: {            
            required: "Template Name is required.",
        },
        zipFile: {            
            required: "Select Zip.",
        }     
    };

    return codeFactory;
}]);
 
